.inputSearch { 
    width: 100%;
    height: 40px;
    flex-direction: row;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    position: relative;
}

.inputSearch .icon {
    color: var(--primary-color);
    position: absolute;
    left: 14px;
    transition: color .3s;
}

.inputSearch .icon.yesFocus {
    color: green;
    color: var(--color-focus);
}

.inputSearch .icon.noFocus {
    color: var(--primary-color);
}

.inputSearch .field {
    width: 100%;
    height: 40px;
    padding: 5px 45px 6px 36px;
    background-color: var(--white-light);
    border: 2px solid #26292c29;
    border-radius: 40px;
    box-sizing: border-box;
    outline: none!important;
    box-shadow: none;
    color: var(--primary-color);
    transition: border .3s;
}

.inputSearch .field.yesFocus {
    border: 2px solid var(--color-focus);
}

.inputSearch .field.noFocus {
    border: 2px solid #26292c29;
}

.inputSearch .buttonIcon {
    position: absolute;
    right: 20px;
    font-size: 16px;
    height: 24px;
    width: 24px;
    background-color: transparent;
    color: #404346;
}

.inputSearch .buttonIcon:hover {
    background: #26292c0d;
    transition: background-color .1s;
}

.inputSearch .historicSearch {
    position: absolute;
    top: 44px;
    border-radius: 5px;
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    overflow: scroll;
    padding: 30px 0px;
    flex-direction: column;
    display: flex;
    vertical-align: middle;
    width: 350px;
    max-height: 350px;
    background-color: var(--white-light);
    z-index: 900;
}

.inputSearch .historicSearch .labelLastHistoricSearch{  
    font-size: 16px;
    font-weight: 500;
    margin-left: 22px;
    margin-right: 20px;
    margin-bottom: 5px;
    color: var(--dark-dark);
}

@media (max-width: 767px){
    .inputSearch .historicSearch {
        display: none;
    }
}