.ownerForm {
    width: 100%;
}

.ownerForm .lineOwnerForm { 
    margin-bottom: 5px;
    display: flex;
    align-items: flex-end;
}

.ownerForm .lineOwnerForm .columnOwnerForm {
    height: 100%;
}

.ownerForm .lineOwnerForm .columnOwnerForm .mandatoryInformation {
    font-size: 12px;
    color: #747678;
}

.ownerForm .lineFooterOwnerForm {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
}

.ownerForm .lineFooterOwnerForm .buttonOwnerForm {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.ownerForm .lineFooterOwnerForm .buttonOwnerForm:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.ownerForm .lineFooterOwnerForm .buttonOwnerForm.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.ownerForm .lineFooterOwnerForm .buttonOwnerForm.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}