.box {
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 16%), 0 2px 2px 0 rgb(0 0 0 / 5%), 0 3px 1px -2px rgb(0 0 0 / 6%), 0 1px 5px 0 rgb(0 0 0 / 10%);
    background-color: var(--white-light);
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    margin-bottom: 24px;
    transition: box-shadow .1s linear;
    background: #fff;
    font: 400 15px/20px Source Sans Pro,sans-serif;
    color: var(--primary-color);
    padding: 20px;
    z-index: 800;
}