.itemHistoric {
    width: 100%;
    padding: 5px 10px;
    background-color: transparent;
    min-height: 60px;
    cursor: pointer;
}

.itemHistoric:hover {
    background-color: var(--white-dark-two);
    transition: background-color 1s;
}

.itemHistoric .colItemHistoric { 
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.itemHistoric .colItemHistoric.detailsItemHistoric {
    display: flex;
    flex-direction: column;
    text-align: left;
    position: relative;    
    overflow: scroll;
}

.itemHistoric .colItemHistoric .iconItemHistoric { 
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.itemHistoric .colItemHistoric .nameItemHistoric {
    color: var(--primary-color);
    font-weight: 600;
    font-size: 16px;
    white-space: nowrap; 
    width: 100%; 
    overflow: hidden;
    text-overflow: ellipsis; 
}

.itemHistoric .colItemHistoric .nameItemHistoric:hover {
    text-decoration: underline;
}

.itemHistoric .colItemHistoric .nameItemHistoric:hover .cardDetailsItemHistoric{
    visibility: visible;
    transition: visibility;
    transition-delay: 1s;
}

.itemHistoric .colItemHistoric .descriptionItemHistoric {
    color: var(--dark-dark);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 4.2;
    width: 100%;
}

.itemHistoric .colItemHistoric .iconItemHistoric.initialsName { 
    color: var(--color-information);
    font-weight: 600;
    font-size: 10px;
    background-color: #c2dfff;
}