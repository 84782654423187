.groupInputArea {  
    width: 100%;
}

.groupInputArea .labelInputArea {  
    color: #747678;
    font: 400 15px/20px 'Source Sans Pro', sans-serif;
    margin-bottom: 4px;
}

.groupInputArea .inputInputArea {  
    position: relative;
}

.groupInputArea .inputInputArea .fieldInputArea {  
    width: 100%;
    border-radius: 2px;
    border: 1px solid #cbcccd;
    box-shadow: inset 0 1px 2px #eee;
    box-sizing: border-box;
    display: block;
    padding: 4px 32px 4px 8px;
    height: 32px;
    font: inherit;
    color: #26292c;
    transition: padding 75ms ease-in-out;
    
}

.groupInputArea .inputInputArea .fieldInputArea.error { 
    border: 1px solid var(--color-error);
}

.groupInputArea .inputInputArea .fieldInputArea:focus {
    border: 1px solid #317ae2;
    box-shadow: inset 0 1px 2px rgb(49 122 226 / 8%);
    outline: none;
}