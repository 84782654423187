.groupInput {
    position: relative;
}

.groupInput .input { 
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    vertical-align: middle;
    overflow: hidden;
}

.groupInput .label {
    position: absolute;
    z-index: 400;
    color: #b9babb;
    font-size: 20px;
    transform: scale(1) translate(30px, 10px);
}

.groupInput .label.yesFocus {
    /* color: green; */
    transition: transform 0.4s;
    transform: scale(0.7) translate(-8px, -25px);
}

.groupInput .label.noFocus {
    /* color: red; */
    transition: transform 0.4s;
    transform: scale(1) translate(30px, 10px);
}

.groupInput .input i.icon {
    left: 0;
    position: absolute;
    color: #b9babb;
    font-size: 20px;
}

.groupInput .input i.icon.yesFocus {
    /* color: green; */
    margin-left: -30px;
    transition: margin-left 0.3s;
}

.groupInput .input i.icon.noFocus {
    margin-left: 0px;
    transition: margin-left 0.3s;
    /* color: red; */
}

.groupInput .input .field{ 
    margin: 0;
    height: 51px;
    border: none;
    font-size: 20px;
    border: 0;
    color: var(--primary-color);
    outline: none;
    border-radius: 0;
    flex: 1;
    transition: padding 0.2s, background-position 0.2s ease;
    border-bottom: 2px solid #cccbdd;
    font-family: 'Source Sans Pro', sans-serif;
    padding-left: 10px;
    z-index: 500;
    background-color: transparent;
}

.groupInput .input .field.noFocus {
    /* border: 2px solid red; */
}

.groupInput .input .field.yesFocus {
    /* border: 2px solid green; */
    border-bottom: 2px solid #08a742;
}