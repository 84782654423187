.button {
    box-shadow: none;
    border: none;
    outline: none;
    background-color: green;
    padding: 20px;
    color: #fff;
    background: #08A742;
    font-weight: 700;
    width: 100%;
    padding: 11px 20px;
    text-align: center;
    outline: none;
    white-space: nowrap;
    font-size: 24px;
    line-height: 1.4em;
    font-family: 'Source Sans Pro', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.button:hover {
    color: #fff;
    background: #26B259;
    transition: background-color 0.3s;
}