.optionSelect {
    background-color: var(--white-light);
    color: var(--primary-color);
    text-align: left;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
    font-weight: 400;
    overflow: hidden;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: space-between;
}

.optionSelect.active {
    font-weight: 600;
}

.optionSelect:hover {
    background-color: var(--color-focus);
    color: var(--white-light);
}

.optionSelect .labelOptionSelect {
}

.optionSelect .labelOptionSelect.lead {
    color: var(--white-light);
    font-size: 11px;
    font-weight: 600;
    padding: 1px 6px;
    border-radius: 2px;

}

.optionSelect .labelOptionSelect.error {
    background-color: var(--color-error);
}

.optionSelect .labelOptionSelect.warning {
    background-color: var(--color-warning);
    color: #000;
}

.optionSelect .labelOptionSelect.focus {
    background-color: var(--color-focus);
}

.optionSelect .iconLabelSelectedOptionSelect {
    color: var(--color-focus);

}

.optionSelect:hover .iconLabelSelectedOptionSelect {
    color: var(--white-light);
}