.fieldEdit {
    border: 1px solid #eee;
    /* border-left: 1px solid transparent; */ /*optional*/
    /* border-bottom: 1px solid transparent; */ /*optional*/
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    padding-left: 10px;
    align-items: center;
}

.fieldEdit .iconFieldEdit {
    color: var(--primary-color);
    padding-right: 10px;
}

.fieldEdit:hover {
    background-color: var(--white-light);
}

.fieldEdit .buttonFieldEdit {
    height: 28px;
    width: 28px;
    background-color: var(--white-light);
    font-size: 12px;
    border: 1px solid var(--dark-light);
    right: 2px;
    top: 1px;
    float: right;
    color: var(--primary-color);
    position: absolute;
    opacity: 0;
}

.fieldEdit:hover .buttonFieldEdit {
    opacity: 1;
}

.fieldEdit .modalFieldEdit {
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: var(--white-light);
    z-index: 700;
    /* max-width: 300px; */
    /* max-height: 130px; */
}