.modalGeneral { 
    background-color: rgba(0, 0, 0, 0.294);
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 1200;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    vertical-align: top;
}

.modalGeneral .contentModalGeneral { 
    background-color: var(--white-light);
    min-width: 300px;
    /* max-width: 800px; */
    border-radius: 5px;
    position: relative;
}

.modalGeneral .contentModalGeneral .lineHeaderModalGeneral {
    display: flex;
    padding: 12px 16px;
    height: 48px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #e5e5e5;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
}

.modalGeneral .contentModalGeneral .lineHeaderModalGeneral .buttonCloseModalGeneral {
    background-color: transparent;
    color: var(--primary-color);
    margin: 0;
    padding: 0;
    width: 30px;
    height: 30px;
}

.modalGeneral .contentModalGeneral .lineHeaderModalGeneral .title {
    font-size: 18px;
    color: #26292c;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.modalGeneral .contentModalGeneral .lineBodyModalGeneral { 
    padding: 13px 15px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.modalGeneral .contentModalGeneral .lineFooterModalGeneral {
    display: flex;
    height: 48px;
    background-color: #f7f7f7;
    border-top: 1px solid #e5e5e5;
    width: 100%;
    align-items: flex-end;
    padding: 12px 48px 12px 16px;

}

.modalGeneral .contentModalGeneral .lineFooterModalGeneral .buttonModalGeneral { 
    /* margin: 0;
    min-width: 60px;
    max-width: 120px;
    height: 40px;
    font-size: 16px; */
}