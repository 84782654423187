.detailsBusiness {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.detailsBusiness .warningDetailsBusiness {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.detailsBusiness .headerNavigationDetailsBusiness {
}

.detailsBusiness .scrollDetailsBusiness {
    overflow: scroll;
    height: 100%;
}

/* start styles header */
/* start styles header */
/* start styles header */
.detailsBusiness .headerDetailsBusiness {
    background-color: var(--white-light);
    padding: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #e5e5e5;
    margin-top: 2px;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness {
    margin-bottom: 16px;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness {
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .modalEditTitleDetailsBusiness {
    position: absolute;
    top: 30px;
    width: 300px;
    height: 140px;
    z-index: 800;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .modalMoreActionsDetailsBusiness {
    position: absolute;
    top: 50px;
    z-index: 800;
    width: 250px;
    min-height: 120px;
    height: 100%;
    padding: 0;
    margin: 0;
    background-color: var(--white-light);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    padding-top: 10px;
    padding-bottom: 10px;
    border: 0.1px solid var(--white-dark-two);
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .modalMoreActionsDetailsBusiness::after {
    width: 18px;
    height: 18px;
    background-color: var(--white-light);
    display: block;
    content: " ";
    position: absolute;
    top: -9px;
    right: 10px;
    z-index: 700;
    transform: rotate(135deg);
    border: none;
    border-left: 1px solid var(--white-dark-two);
    border-bottom: 1px solid var(--white-dark-two);
    /* box-shadow: 0px 0px 7px 1px var(--white-dark-two); */
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .modalMoreActionsDetailsBusiness .buttonMoreActionsDetailsBusiness {
    border: none;
    font-size: 15px;
    background-color: transparent;
    color: var(--dark-dark);
    text-align: left;
    justify-content: flex-start;
    padding: 0;
    font-weight: 400;
    padding: 8px 20px;
    width: 100%;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .modalMoreActionsDetailsBusiness .buttonMoreActionsDetailsBusiness:hover {
    padding: 0;
    padding: 8px 20px;
    background-color: var(--color-focus);
    color: var(--white-light);
    transition: none;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .modalEditMoneyDetailsBusiness {
    position: absolute;
    top: 80px;
    width: 300px;
    height: 140px;
    z-index: 800;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .titleHeaderInfoDetailsBusiness {
    color: var(--color-focus);
    font-size: 27px;
    font-weight: 400;
    display: initial;
    cursor: pointer;
    margin: 0;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .titleHeaderInfoDetailsBusiness:hover {
    background-color: #fffcdc;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness {
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness .columnEditStampHeaderDetailsBusiness {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness .columnEditStampHeaderDetailsBusiness .nameStampHeaderDetailsBusiness { 
    font-size: 11px;
    padding: 1px 4px;
    border-radius: 2px;
    color: var(--white-light);
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 8px;
    text-align: center;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness .columnEditStampHeaderDetailsBusiness .nameStampHeaderDetailsBusiness.warning {
    color: black;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness .columnEditStampHeaderDetailsBusiness .modalEditStampDetailsBusiness { 
    position: absolute;
    top: 30px;
    width: 300px;
    min-height: 140px;
    z-index: 800;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness .columnEditStampHeaderDetailsBusiness .notificationStampDetailsBusiness{
    display: none;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness .columnEditStampHeaderDetailsBusiness:hover .notificationStampDetailsBusiness{
    background-color: black;
    color: white;
    padding: 4px;
    border-radius: 5px;
    position: absolute;
    top: -25px;
    min-width: 60px;
    z-index: 600;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    display: block;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness .columnEditStampHeaderDetailsBusiness .buttonStampHeaderDetailsBusiness {
    width: 25px;
    height: 25px;
    background-color: transparent;
    color: var(--dark-dark-three);
    font-size: 14px;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness .columnEditStampHeaderDetailsBusiness .buttonStampHeaderDetailsBusiness:hover {
    background-color: var(--white-dark);
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineBottomMoreInfoDetailsBusiness {
    margin-top: 12px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: flex-start;
    line-height: 30px;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineBottomMoreInfoDetailsBusiness .moneyInfoDetailsBusiness {
    line-height: 24px;
    font-weight: 400;
    font-size: 23px;
    color: var(--primary-color);
    cursor: pointer;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineBottomMoreInfoDetailsBusiness .moneyInfoDetailsBusiness:hover {
    background-color: #fffcdc;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineBottomMoreInfoDetailsBusiness .ownersInfoDetailsBusiness {
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineBottomMoreInfoDetailsBusiness .ownersInfoDetailsBusiness::before {
    content: " ";
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    width: 1px;
    min-height: 25px;
    margin-left: 16px;
    margin-right: 16px;
    background-color: var(--white-dark-two);
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineBottomMoreInfoDetailsBusiness .ownersInfoDetailsBusiness .iconUserMoreInfoDetailsBusiness {
    font-size: 18px;
    color: #404346;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineBottomMoreInfoDetailsBusiness .ownersInfoDetailsBusiness .nameUserMoreInfoDetailsBusiness {
    color: var(--primary-color);
    margin-left: 6px;
    font-size: 16px;
    font-weight: 400;
    
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .buttonStatusHeaderInfoDetailsBusiness {
    min-width: 32px;
    max-width: 100px;
    margin: 5px;
    height: 32px;
    font-size: 14px;
    font-weight: 600;
    background: var(--white-light);
    color: var(--white-light);
    border: 0;
    border: none;
    border-radius: 2px;
    margin-top: 0;
    padding: 0 4px;
    margin-bottom: 0px;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .buttonStatusHeaderInfoDetailsBusiness:hover {
    opacity: 0.8;
    transition: opacity 0.8s;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .buttonStatusHeaderInfoDetailsBusiness.positive {
    background-color: var(--color-success);
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .buttonStatusHeaderInfoDetailsBusiness.negative {
    background-color: var(--color-error);
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .buttonMoreActionsHeaderInfoDetailsBusiness {
    height: 32px;
    width: 32px;
    background: #fff;
    border: 0;
    border-radius: 2px;
    margin: 5px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    color: var(--primary-color);
    border: 1px solid #cbcccd;

}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .buttonMoreActionsHeaderInfoDetailsBusiness:hover {
    background-color: var(--white-dark);
    transition: background-color .12s;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .dividerMoreActionsDetailsBusiness {
    width: 100%;
    height: 1px;
    background-color: var(--white-dark-two);
    margin-top: 10px;
    margin-bottom: 10px;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: row;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness .avatarOwnerHeaderDetailsBusiness {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid var(--white-dark-two);
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness .nameOwnerHeaderDetailsBusiness {
    padding: 0;
    margin: 0 5px 0 10px;
    line-height: 18px;
    text-align: right;
    display: flex;
    flex-direction: column;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness .notificationOwnerDetailsBusiness {
    background-color: black;
    color: white;
    padding: 4px;
    border-radius: 5px;
    position: absolute;
    top: -25px;
    min-width: 60px;
    z-index: 600;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    display: none;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness:hover .notificationOwnerDetailsBusiness {
    display: block;    
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness .nameOwnerHeaderDetailsBusiness .modalEditOwnerDetailsBusiness{
    position: absolute;
    top: 40px;
    left: 0;
    width: 300px;
    height: 140px;
    z-index: 800;
    text-align: left;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness .nameOwnerHeaderDetailsBusiness .anchorNameOwnerHeaderDetailsBusiness {
    padding: 0;
    margin: 0;
    font-weight: 600;
    display: flex;
    justify-content: flex-end;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness .nameOwnerHeaderDetailsBusiness .anchorNameOwnerHeaderDetailsBusiness:hover {
    text-decoration: underline!important;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness .nameOwnerHeaderDetailsBusiness .levelNameOwnerHeaderDetailsBusiness {
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: var(--dark-light);
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness .buttonEditOwnerHeaderDetailsBusiness {
    width: 25px;
    height: 25px;
    background-color: transparent;
    color: var(--dark-dark-three);
    font-size: 14px;
    margin-right: 10px;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness .buttonEditOwnerHeaderDetailsBusiness:hover {
    background-color: var(--white-dark);
}

/* ends styles header */
/* ends styles header */
/* ends styles header */


/* start styles body */
/* start styles body */
/* start styles body */
.detailsBusiness .bodyDetailsBusiness {
    height: 100%;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness {
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .headerBoxOthersBodyDetailsBusiness {
    border-bottom: 1px solid #e5e5e5;
    display: inline-flex;
    justify-content: space-between;
    vertical-align: middle;
    align-items: center;
    padding-bottom: 16px;
    width: 100%;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .headerBoxOthersBodyDetailsBusiness .buttonEditPersonOthersDetailsBusiness {
    width: 25px;
    height: 25px;
    background-color: transparent;
    color: var(--dark-dark-three);
    font-size: 14px;
    color: var(--primary-color);
    border: 1px solid var(--white-dark-two);
    padding: 4px;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .headerBoxOthersBodyDetailsBusiness .buttonEditPersonOthersDetailsBusiness:hover {
    background-color: var(--white-dark);
}


.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .headerBoxOthersBodyDetailsBusiness .subtitleOthersDetailsBusiness {
    font-size: 13px;
    color: #721ea9;
    font-weight: 600;
    text-transform: uppercase;
    width: 100%;
    display: block;
    
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness {
    margin: 8px 0;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .colOne {
    padding-right: 10px;
    display: flex;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .colOne .avatarClientOthersDetailsBusiness {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: var(--color-focus);
    fill: var(--color-focus);
    border: 2px solid rgba(38,41,44,.64);
    box-sizing: border-box;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .colOne span {
    font: 400 13px/16px Source Sans Pro,sans-serif;
    font-weight: 600;
    color: rgba(38,41,44,.64);
    text-align: right;
    word-wrap: break-word;
    margin: 2px 0;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .colTwo {
    padding-left: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .buttonBodyOthersDetailsBusiness {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .buttonBodyOthersDetailsBusiness:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .buttonBodyOthersDetailsBusiness.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
    margin-left: 10px;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .buttonBodyOthersDetailsBusiness.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .colTwo span.nameClientBodyOthersDetailsBusiness{
    color: black;
    font-size: 18px;
    font-weight: 600;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .colTwo span {
    color: var(--primary-color);
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness span.minumus {
    color: #747678;
    font-size: 13px;
    line-height: 9.2px;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness.general {
    margin-top: 15px;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .progressOthersDetailsBusiness {
    width: 100%;
    display: block;
    content:  " ";
    background-color: var(--color-success);
    border-radius: 120px;
    height: 16px;
    margin-bottom: 10px;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .progressOthersDetailsBusiness:hover {
    transform: scaleX(1.2);
    transform: scaleY(1.3);
}

.detailsBusiness .bodyDetailsBusiness .boxActivitiesBodyDetailsBusiness {
    padding: 0;
}

.detailsBusiness .bodyDetailsBusiness .menusActivitiesDetailsBusiness {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: hidden;
}

.detailsBusiness .bodyDetailsBusiness .boxActivitiesHistoricBodyDetailsBusiness {
    background-color: transparent;
    box-shadow: none;
    border: none;
    padding: 20px 0;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    vertical-align: middle;
    margin-bottom: 200px;
    min-height: 1000px;
}

.detailsBusiness .bodyDetailsBusiness .boxActivitiesHistoricBodyDetailsBusiness .statusActivitiesDetailsBusiness {
    background-color: #747678;
    border-radius: 180px;
    color: white;
    padding: 1px 20px;
    margin: 10px;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 14px;
}

/* ends styles body */
/* ends styles body */
/* ends styles body */


@media (min-width: 767px) and (max-width: 1024px)
{
    .detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness .columnEditStampHeaderDetailsBusiness .modalEditStampDetailsBusiness {
        position: fixed;
        top: 30px;
        left: 60px;
    }
}

@media (max-width: 425px)
{
    .detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineBottomMoreInfoDetailsBusiness {
        flex-direction: column;        
    }

    .detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineBottomMoreInfoDetailsBusiness .ownersInfoDetailsBusiness::before {
        display: none;
    }
}