.tableEditCommissionRules {
    background-color: var(--white-light);
}

.tableEditCommissionRules .helpTableEditCommissionRules {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;

}

.tableEditCommissionRules table, tr, th, td {
    margin: 0;
}

.tableEditCommissionRules table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditCommissionRules table .buttonCheckTableEditCommissionRules {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.tableEditCommissionRules table .buttonCheckTableEditCommissionRules .squareCheck {
    border: 2px solid var(--white-dark-two);
    margin: 0;
    border-radius: 2px;
}

.tableEditCommissionRules table .buttonCheckTableEditCommissionRules.active .squareCheck {
    background-color: var(--color-focus);
    border: 2px solid var(--color-focus);
}

.tableEditCommissionRules table .buttonCheckTableEditCommissionRules.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.tableEditCommissionRules table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
  
}

.tableEditCommissionRules table tr {
    height: 32px;
}

.tableEditCommissionRules .theadTableEditCommissionRules td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
}

.tableEditCommissionRules .theadTableEditCommissionRules td:last-of-type {
    border-right: none;
}

.tableEditCommissionRules td.checkTableEditCommissionRules {
    text-align: center;
    vertical-align: middle;
    width: 35px!important;
    padding: 0;
}

.tableEditCommissionRules .tbodyTableEditCommissionRules:hover {
    background-color: var(--white-dark);
}

.tableEditCommissionRules .tbodyTableEditCommissionRules td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
}

.tableEditCommissionRules .table .tbodyTableEditCommissionRules td.checkTableEditCommissionRules {
    border: 1px solid #eee;
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
}

.tableEditCommissionRules .tbodyTableEditCommissionRules td:hover .buttonEditTableEditCommissionRules {
    opacity: 1;
}

.tableEditCommissionRules .tbodyTableEditCommissionRules td:hover .editTableEditCommissionRules {    
    border: 1px solid var(--color-focus)!important;
}

.tableEditCommissionRules .tbodyTableEditCommissionRules td .editTableEditCommissionRules.noEdit .buttonFieldEdit {
    display: none;
}

.tableEditCommissionRules .tbodyTableEditCommissionRules td .stampEditCommissionRules {
    position: absolute;
    z-index: 900;
    height: 20px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    font-size: 11px;
    padding: 1px 4px;
    border-radius: 2px;
    color: var(--white-light);
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 8px;
    text-align: center;
    color: var(--white-light);

}

.tableEditCommissionRules .tbodyTableEditCommissionRules td .editTableEditCommissionRules .modalFieldEdit {
    z-index: 1000;
}