.companyPreferences {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.companyPreferences .scrollCompanyPreferences {
    overflow: scroll;
    height: 100%;
    margin-bottom: 300px;
}

.companyPreferences .headerCompanyPreferences {
    margin-top: 20px;
}

.companyPreferences .headerCompanyPreferences h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.companyPreferences .headerCompanyPreferences h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-warning);
    transform: rotate(45deg);
}

.companyPreferences .bodyCompanyPreferences {
    margin-bottom: 300px;
}

.companyPreferences .bodyCompanyPreferences .menusConfigCompanyPreferences .buttonMenus {
    background-color: transparent;
    border-bottom: 2px solid transparent;
    font-weight: 400;
}

.companyPreferences .bodyCompanyPreferences .menusConfigCompanyPreferences .buttonMenus.active {
    border-bottom: 2px solid var(--color-focus);
}

.companyPreferences .bodyCompanyPreferences .menusConfigCompanyPreferences .buttonMenus:hover {
    border-bottom: 2px solid var(--white-dark-two);
    transition: border 1.5s;
}

.companyPreferences .bodyCompanyPreferences .menusConfigCompanyPreferences .buttonMenus.active:hover {
    border-bottom: 2px solid var(--color-focus);
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences {
    width: 100%;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences {
    width: 100%;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .subtitleBodyConfigCompanyPreferences {
    color: #747678;
    font: 400 15px/20px 'Source Sans Pro', sans-serif;
    margin-bottom: 4px;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .buttonPlusFinancialServicesConfigCompanyPreferences,
.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .buttonPlusReasonConfigCompanyPreferences {
    background-color: var(--color-success);
    color: var(--white-light);
    border-radius: 50%;
    margin-bottom: 10px;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .editReasonConfigCompanyPreferences {   
    height: 150px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 900;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences { 
    position: absolute;
    top: 0;
    right: 0;
    width: 340px;
    z-index: 900;
    padding: 0;
    margin: 0;
    height: 245px;
    background-color: var(--white-light);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: var(--white-light);
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    overflow: hidden;    
    margin-left: auto;
    margin-right: auto;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences .dropHeaderEditFinancialServicesConfigCompanyPreferences {
    padding: 15px;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences .dropHeaderEditFinancialServicesConfigCompanyPreferences .titleDropEditCompanyPreferences { 
    color: var(--dark-light);
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences .dropBodyEditFinancialServicesConfigCompanyPreferences { 
    padding: 15px;
    padding-top: 0;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences .dropBodyEditFinancialServicesConfigCompanyPreferences .inputFinancialServicesConfigCompanPreferences { 
    margin-bottom: 10px;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences .dropFooterEditFinancialServicesConfigCompanyPreferences {
    border-top: 1px solid var(--white-dark-two);
    background-color: var( --white-dark);
    height: 100%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    padding: 0 15px;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences .dropFooterEditFinancialServicesConfigCompanyPreferences .buttonDropEditCompanyPreferences {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 80px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
    /* width: 50px; */
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences .dropFooterEditFinancialServicesConfigCompanyPreferences .buttonDropEditCompanyPreferences:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences .dropFooterEditFinancialServicesConfigCompanyPreferences .buttonDropEditCompanyPreferences.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences .dropFooterEditFinancialServicesConfigCompanyPreferences .buttonDropEditCompanyPreferences.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .lineReasonBodyConfigCompanyPreferences {
    border-top: 1px solid #e5e5e5;
    padding: 5px 10px;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .lineReasonBodyConfigCompanyPreferences:hover {
    background-color: var(--white-dark);
    transition: background-color 0.8s;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .lineReasonBodyConfigCompanyPreferences:last-of-type {
    border-bottom: 1px solid #e5e5e5;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .nameReasonBodyConfigCompanyPreferences {
    color: var(--color-focus);
    cursor: pointer;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .lineReasonBodyConfigCompanyPreferences .buttonReasonBodyConfigCompanyPreferences {
    font-size: 10px;
    width: initial;
    background-color: transparent;
    color: var(--color-focus);
    font-weight: 400;
    margin: 0;
    padding: 0;
    display: none;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .lineReasonBodyConfigCompanyPreferences:hover .buttonReasonBodyConfigCompanyPreferences {
    display: flex;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .lineReasonBodyConfigCompanyPreferences .buttonReasonBodyConfigCompanyPreferences.negative {
    color: var(--color-error);
    position: relative;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .lineReasonBodyConfigCompanyPreferences .buttonReasonBodyConfigCompanyPreferences.negative::before {
    width: 2px;
    height: 2px;
    border-radius: 50%;
    content: " ";
    display: block;
    background-color: var(--primary-color);
    margin-left: 4px;
    margin-right: 4px;
}   

.companyPreferences .bodyCompanyPreferences .lineFooterConfigCompanyPreferences {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
}

.companyPreferences .bodyCompanyPreferences .lineFooterConfigCompanyPreferences .columnFooterConfigCompanyPreferences {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    flex-direction: row;
    padding-left: 13px;
    padding-right: 13px;
    background-color: var(--dark-dark-three);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 100%;
}

.companyPreferences .bodyCompanyPreferences .lineFooterConfigCompanyPreferences .columnFooterConfigCompanyPreferences .buttonFooterConfigCompanyPreferences {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
    width: 100px!important;

}

.companyPreferences .bodyCompanyPreferences .lineFooterConfigCompanyPreferences .columnFooterConfigCompanyPreferences .buttonFooterConfigCompanyPreferences:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.companyPreferences .bodyCompanyPreferences .lineFooterConfigCompanyPreferences .columnFooterConfigCompanyPreferences .buttonFooterConfigCompanyPreferences.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.companyPreferences .bodyCompanyPreferences .lineFooterConfigCompanyPreferences .columnFooterConfigCompanyPreferences .buttonFooterConfigCompanyPreferences.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}