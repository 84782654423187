.modalEdit {
    padding: 0;
    margin: 0;
    background-color: var(--white-light);
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 5px;
    background-color: var(--white-light);
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    position: relative;
    /* overflow: hidden; */
}

.modalEdit .headerModalEdit {
    padding: 15px;
}

.modalEdit .headerModalEdit .titleModalEdit {
    color: var(--dark-light);
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.modalEdit .bodyModalEdit {
    padding: 15px;
    padding-top: 0;
}

.modalEdit .footerModalEdit {
    border-top: 1px solid var(--white-dark-two);
    background-color: var( --white-dark);
    height: 100%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    padding: 0 15px;
}

.modalEdit .footerModalEdit .buttonModalEdit {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 80px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
    /* width: 50px; */
}

.modalEdit .footerModalEdit .buttonModalEdit:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.modalEdit .footerModalEdit .buttonModalEdit.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.modalEdit .footerModalEdit .buttonModalEdit.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}