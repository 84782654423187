.cardChartPie {
    position: relative;
    width: 200px;
}

.cardChartPie .labelOverCardChartPie {
    /* start alignment */
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    width: 130px;
    height: 130px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* ends alignment */   
    

    /* start general style */
    border: 2px solid var(--color-success);
    border-style: dotted;
    border-radius: 50%;
    font-weight: 600;
    font-size: 42px;
    color: var(--color-success);
    /* ends general style */
}