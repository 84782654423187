.cardDetailsBusiness {
    position: fixed;
    width: 400px;
    height: 250px;
    z-index: 500;
    color: var(--dark-dark);
    display: flex;
    flex-direction: column;
    top: 120px;
    display: none;
    border-radius: 5px;
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    padding: 30px 0px;
    flex-direction: column;
    display: flex;
    vertical-align: middle;
    background-color: var(--white-light);
    visibility: hidden;
    padding: 10px;
    
}

.cardDetailsBusiness span { 
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
    width: 100%;
} 

.cardDetailsBusiness .lineCardDetailsBusiness {
    width: 100%;
    height: 100%;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    height: 100%;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .progressLineDetailsBusiness .stageContentProgressLine {
    width: 19%;
}   

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .progressLineDetailsBusiness .stageContentProgressLine {
   font-size: 0;
   color: transparent;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness.columnIconCardDetailsBusiness {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .anchorNameCardDetailsBusiness {
    font-size: 15px;
    font-weight: 400;
    width: initial;
    margin-left: 0;
    padding-left: 0;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .anchorNameCardDetailsBusiness:hover {
    color: var(--color-focus);
    text-decoration: underline!important;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .detailItem{    
    font-size: 15px;
    font-weight: 400;
    cursor: default;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .iconCardDetailsBusiness {
    font-size: 14px;
    color: var(--dark-light);
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .iconCardDetailsBusiness.big {
    font-size: 24px;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .moreCardDetailsBusiness {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .moreCardDetailsBusiness .anchorCardDetailsBusiness {
    margin-left: 0;
    padding-left: 0;
    margin-top: 0;
    padding-bottom: 0;
    font-size: 24px;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .moreCardDetailsBusiness .anchorCardDetailsBusiness:hover {
    color: var(--color-focus);
    text-decoration: underline!important;
}
