.owners {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.owners .containerNavigationHeaderOwners {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.owners .containerNavigationHeaderOwners .buttonNavigationHeaderOwners {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.owners .containerNavigationHeaderOwners .buttonNavigationHeaderOwners:hover {
    background-color: var(--white-dark);
}

.owners .containerNavigationHeaderOwners .buttonNavigationHeaderOwners:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.owners .containerNavigationHeaderOwners .buttonNavigationHeaderOwners:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.owners .containerNavigationHeaderOwners .buttonNavigationHeaderOwners.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.owners .containerNavigationHeaderOwners .buttonPlusNavigationHeaderOwners {
    width: 120px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.owners .containerNavigationHeaderOwners .buttonTrashNavigationHeaderOwners {
    font-size: 10px;
    width: 32px;
    height: 32px;
    color: var(--primary-color);
    background-color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
    border: 1px solid var(--white-dark-two);
}

.owners .containerNavigationHeaderOwners .filterFontsHeaderOwners {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.owners .containerNavigationHeaderOwners .filterFontsHeaderOwners .iconFilterFontsHeaderOwners {
    color: red;
    font-size: 14px;
    color: var(--dark-dark);
}

.owners .containerNavigationHeaderOwners .filterFontsHeaderOwners .selectFontHeaderOwners {
    cursor: pointer;
    width: 100%;
    border: none;
}

.owners .containerNavigationHeaderOwners .filterFontsHeaderOwners .selectFontHeaderOwners .textShowSelectMulti {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.owners .containerNavigationHeaderOwners .filterFontsHeaderOwners .selectFontHeaderOwners .fieldSelectMulti {
    border: none;
}