.dashboard {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.dashboard .headerDashboard {
}

.dashboard .containerBodyDashboard {
    background-color: var(--white-dark);
    position: relative;
    height: calc(100vh - 56px); /*56px by header component*/
    overflow: scroll;
}

.dashboard .containerBodyDashboard .lineBodyDashboard {
    width: 100%;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard {
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaLegendChartSimpleBarDashboard {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    vertical-align: middle;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaLegendChartSimpleBarDashboard span::before {
    background-color: var(--color-success);
    width: 10px;
    height: 10px;
    border-radius: 2px;
    content: " ";
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    margin-right: 5px;
    font-size: 14px;
    color: var(--dark-dark);
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaLegendChartSimpleBarDashboard span.success::before {
    background-color: var(--color-success);
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaLegendChartSimpleBarDashboard span.error::before {
    background-color: var(--color-error);
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaLegendChartSimpleBarDashboard span.warning::before {
    background-color: var(--color-warning);
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaLegendChartSimpleBarDashboard span.information::before {
    background-color: var(--color-information);
}