.detailsPermissions {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.detailsPermissions .warningDetailsPermissions {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.detailsPermissions .scrollDetailsPermissions {
    overflow: scroll;
    height: 100%;
}

.detailsPermissions .headerDetailsPermissions {
    margin-top: 20px;
}

.detailsPermissions .headerDetailsPermissions h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.detailsPermissions .headerDetailsPermissions h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-success);
    transform: rotate(45deg);

}

.detailsPermissions .bodyDetailsPermissions  {
    margin-bottom: 250px;
}

.detailsPermissions .bodyDetailsPermissions .lineCollectionsDetailsPermissions .collectionsPermissionsGroupDetailsPermissions .labelSelectMulti {
    text-align: center;
    width: 100%;
}

.detailsPermissions .bodyDetailsPermissions .lineCollectionsDetailsPermissions .collectionsPermissionsGroupDetailsPermissions .fieldSelectMulti {
    border-radius: 120px;
    height: 38px;
}

.detailsPermissions .bodyDetailsPermissions .lineTitleHeaderChecksConfigDetailsPermissions {
    background-color: var(--dark-dark-three);
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 48px;
}

.detailsPermissions .bodyDetailsPermissions .lineTitleHeaderChecksConfigDetailsPermissions .columnTitleHeaderChecksConfigDetailsPermissions {
    color: var(--white-light);
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.detailsPermissions .bodyDetailsPermissions .lineHeaderChecksConfigDetailsPermissions {
    background-color: var(--white-dark-two);
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
}

.detailsPermissions .bodyDetailsPermissions .lineHeaderChecksConfigDetailsPermissions .columnHeaderChecksConfigDetailsPermissions {
    padding: 0;
    padding-left: 20px;   
    padding-right: 20px;
    font-weight: 600;
    text-transform: uppercase;
}

.detailsPermissions .bodyDetailsPermissions .lineHeaderChecksConfigDetailsPermissions .columnHeaderChecksConfigDetailsPermissions .checkHeaderConfigDetailsPermissions {
    width: initial!important;
    margin-left: auto;
    margin-right: auto;
}

.detailsPermissions .bodyDetailsPermissions .lineHeaderChecksConfigDetailsPermissions .columnHeaderChecksConfigDetailsPermissions .checkHeaderConfigDetailsPermissions .squareCheck {
    margin: 0;
}

.detailsPermissions .bodyDetailsPermissions .lineHeaderChecksConfigDetailsPermissions .columnHeaderChecksConfigDetailsPermissions .checkHeaderConfigDetailsPermissions.active .squareCheck {
    border-color: var(--color-success);
    background-color: var(--color-success);
}

.detailsPermissions .bodyDetailsPermissions .lineHeaderChecksConfigDetailsPermissions .columnHeaderChecksConfigDetailsPermissions .checkHeaderConfigDetailsPermissions.active .iconCheck {
    color: var(--white-light);
}

.detailsPermissions .bodyDetailsPermissions .lineBodyChecksConfigDetailsPermissions {
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid var(--white-dark-two);
    border-top: none;
}

.detailsPermissions .bodyDetailsPermissions .lineBodyChecksConfigDetailsPermissions .columnBodyChecksConfigDetailsPermissions {
    padding: 0;
    padding-left: 20px;   
    padding-right: 20px;
    font-weight: 300;
}

.detailsPermissions .bodyDetailsPermissions .lineBodyChecksConfigDetailsPermissions .columnBodyChecksConfigDetailsPermissions .checkBodyConfigDetailsPermissions {
    width: initial;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
}

.detailsPermissions .bodyDetailsPermissions .lineBodyChecksConfigDetailsPermissions .columnBodyChecksConfigDetailsPermissions .checkBodyConfigDetailsPermissions .squareCheck {
    margin: 0;
}

.detailsPermissions .bodyDetailsPermissions .lineBodyChecksConfigDetailsPermissions .columnBodyChecksConfigDetailsPermissions .checkBodyConfigDetailsPermissions.active .squareCheck {
    border-color: var(--color-success);
    background-color: var(--color-success);
}

.detailsPermissions .bodyDetailsPermissions .lineBodyChecksConfigDetailsPermissions .columnBodyChecksConfigDetailsPermissions .checkBodyConfigDetailsPermissions.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.detailsPermissions .bodyDetailsPermissions .lineFooterChecksConfigDetailsPermissions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
    background-color: var(--dark-dark-three);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.detailsPermissions .bodyDetailsPermissions .lineFooterChecksConfigDetailsPermissions .buttonCheckConfigDetailsPermissions {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.detailsPermissions .bodyDetailsPermissions .lineFooterChecksConfigDetailsPermissions .buttonCheckConfigDetailsPermissions:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.detailsPermissions .bodyDetailsPermissions .lineFooterChecksConfigDetailsPermissions .buttonCheckConfigDetailsPermissions.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.detailsPermissions .bodyDetailsPermissions .lineFooterChecksConfigDetailsPermissions .buttonCheckConfigDetailsPermissions.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}