.messageConfirmation {
    background-color: rgba(0, 0, 0, 0.342);
}

.modal-content { /* bootstrap class */
    background-color: transparent;
    border: none;
}

.messageConfirmation .content {
    border-radius: 20px;
    background-color: var(--white-light);
    overflow: hidden;
}

/* Start styling header */
/* Start styling header */
/* Start styling header */
.messageConfirmation .content .header {
    border-bottom: 2px solid var(--dark-dark);
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    padding: 30px;
}

.messageConfirmation .content .header .status{
    font-weight: 600;
    font-size: 16px;
}

.messageConfirmation .content .header .status.success{ color: #28a745 }

.messageConfirmation .content .header .status.error{ color: #dc3545 }

.messageConfirmation .content .header .status.warning{ color: #ffc107 }

.messageConfirmation .content .header .status.information{ color: #007bff }

.messageConfirmation .content .header .status .iconStatus{
    margin-right: 10px;
    font-size: 22x;
}

.messageConfirmation .content .header .close{
    border: 1px solid var(--dark-dark);
    padding: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: transparent!important;
}
.messageConfirmation .content .header .button .iconButton{
    color: var(--dark-dark);
}
/* Ends styling header */
/* Ends styling header */
/* Ends styling header */

/* Start styling body */
/* Start styling body */
/* Start styling body */
.messageConfirmation .content .body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    text-align: justify;
    padding: 30px;
    flex-direction: column;
}

.messageConfirmation .content .body p {
    font-size: 18px;
    font-weight: 400;
    color: var(--dark-dark);
}

.messageConfirmation .content .body .lineBodyMessageConfirmation {
    width: 100%;
}

.messageConfirmation .content .body .lineBodyMessageConfirmation .columnBodyMessageConfirmation .buttonMessageConfirmation {
    width: 100px;
    font-weight: 400;
    height: 40px;
    font-size: 14px;
    margin: 0;
    background-color: var(--color-error);
}

.messageConfirmation .content .body .lineBodyMessageConfirmation .columnBodyMessageConfirmation .buttonMessageConfirmation.positive {
    background-color: var(--color-success);
}
/* Ends styling body */
/* Ends styling body */
/* Ends styling body */