.modalEditStamp {
    padding: 0;
    margin: 0;
    background-color: var(--white-light);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: var(--white-light);
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    position: relative;
    /* overflow: hidden; */
}

.modalEditStamp .headerModalEditStamp {
    padding: 15px;
    border-bottom: 1px solid var(--white-dark-two);
}

.modalEditStamp .headerModalEditStamp .titleModalEditStamp {
    color: var(--dark-light);
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.modalEditStamp .bodyModalEditStamp {
    padding: 5px 0px;
}

.modalEditStamp .bodyModalEditStamp.active {
    padding: 15px;
}

.modalEditStamp .bodyModalEditStamp .lineChooseColorModalEditStamp {
    display: inline-flex;
    justify-content: center;
    vertical-align: middle;
    width: 100%;
    padding: 5px;
}

.modalEditStamp .bodyModalEditStamp .lineChooseColorModalEditStamp .buttonChooseColorModalEditStamp {
    border-radius: 4px;
    width: 25px;
    height: 25px;
    margin: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
}

.modalEditStamp .bodyModalEditStamp .lineChooseColorModalEditStamp .buttonChooseColorModalEditStamp:hover {
    opacity: 0.8;
}

.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp {
    padding: 0;
    margin: 0;
    margin-top: 8px;
    padding: 8px 20px;
    margin-bottom: 8px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    position: relative;
    cursor: pointer;
}

.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .buttonEditStampModalEditStamp {
    color: var(--white-light);
    font-size: 12px;
    cursor: pointer;
}

.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .iconCheckedStampModalEditStamp {
    color: var(--color-focus);
    font-size: 12px;
}

.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp:hover {
    background-color: var(--color-focus);
}

.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .stampModelEditStamp {
    font-size: 11px;
    padding: 1px 4px;
    border-radius: 2px;
    color: var(--dark-dark-three);
    font-weight: 600;
    text-transform: uppercase;
}

.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .stampModelEditStamp.none {
    border: 1px solid var(--dark-dark-three);
}

.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp:hover .stampModelEditStamp.none {
    border-color: var(--white-light);
    color: var(--white-light);
}   

.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .stampModelEditStamp.warning {
    color: black;
}

.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .stampModelEditStamp.success,
.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .stampModelEditStamp.purple,
.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .stampModelEditStamp.focus,
.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .stampModelEditStamp.error {
    color: var(--white-light);
}

.modalEditStamp .bodyModalEditStamp .lineChooseColorModalEditStamp .buttonChooseColorModalEditStamp .iconCheckedColorModalEditStamp {
    font-size: 10px;
    color: black;
}

.modalEditStamp .footerModalEditStamp { 
    border-top: 1px solid var(--white-dark-two);
    background-color: var(--white-light);
    height: 100%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
}

.modalEditStamp .footerModalEditStamp.active { 
    padding: 8px 15px;
}

.modalEditStamp .footerModalEditStamp .buttonModalEditStamp {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 80px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.modalEditStamp .footerModalEditStamp .buttonPlusModalEditStamp {
    color: var(--color-focus);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    width: 100%;
    font-weight: 400;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: transparent;
    border-radius: 2px;
    border: none;
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: left;
}

.modalEditStamp .footerModalEditStamp .buttonPlusModalEditStamp:hover {
    background-color: var(--color-focus);
    color: var(--white-light);
}

.modalEditStamp .footerModalEditStamp .buttonModalEditStamp:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.modalEditStamp .footerModalEditStamp .buttonModalEditStamp.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.modalEditStamp .footerModalEditStamp .buttonModalEditStamp.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}