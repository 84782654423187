.anchor {
    padding: 10px;
    margin: 5px;
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    text-decoration: none!important;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}