.draggable {  
	cursor: pointer;
	border: none;
	min-width: 50px;
	min-height: 80px;
	background-color: var(--white-light);
	border-radius: 2px;
	display: flex;
	justify-content: center;
	align-items: center;
	vertical-align: middle;
	color: var(--primary-color);
	margin: 5px 10px;
	border: 1px solid var(--white-dark-two);
}

.draggable:hover {
	box-shadow: rgb(0 0 0 / 16%) 0px 0px 1px 0px, rgb(0 0 0 / 5%) 0px 2px 2px 0px, rgb(0 0 0 / 6%) 0px 3px 1px -2px, rgb(0 0 0 / 10%) 0px 1px 5px 0px;
	transition: box-shadow 1s;
}