@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Archivo+Black&display=swap);
:root {
    --primary-color: #26292c;

    --dark-dark: #353535;
    --dark-light: #949494;
    --dark-dark-two: #202225;
    --dark-dark-three: #696969;
    
    --white-light: #fff;
    --white-dark: #f7f7f7;
    --white-dark-two: #dcdddd;
    
    --color-error: #dc3545;
    --color-success: #28a745;
    --color-warning: #ffc107;
    --color-information: #007bff;
    --color-focus: #317ae2;

    --color-purple: purple;
    
}

*{
    padding: 0;
    margin: 0;
}

body {  
    background-color: #f7f7f7;  
    background-color: var(--white-dark);
    font-family: 'Source Sans Pro', sans-serif;
}

::-webkit-scrollbar {
    display: none;
}

button:focus-visible, button:focus {
    outline: none;
    border: none;
    box-shadow: none;
}

select { /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
}

.menusCRM {
    padding: 0;
    margin: 0;
    background-color: #404346;
    width: 56px;
    height: 100%;
}

.menusCRM .anchorMenu {
    text-decoration: none;    
    position: relative;
    display: flex;
    align-items: center;
    vertical-align: middle;
    text-align: left;
}

.menusCRM .anchorMenu .squareMenu {
    width: 56px;
    height: 56px;
    border: none;
    text-align: center;
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    color: var(--white-dark-two);
    font-size: 24px;
}

.menusCRM .anchorMenu .squareMenu.brandMenu {
    fill: red;
    color: red;
}

.menusCRM .anchorMenu .squareMenu:hover {
    background-color: rgba(255, 255, 255, 0.16);
    transition: background-color 0.2s;
}

.menusCRM .anchorMenu .squareMenu.active {
    background-color: var(--dark-dark-two);
}

.menusCRM .anchorMenu .flagMenu.active {
    content: "";
    width: 4px;
    border-radius: 0px 4px 4px 0px;
    background-color: rgb(255, 255, 255);
    position: absolute;
    top: 8px;
    bottom: 8px;
    left: 0px;
}

.menusCRM .anchorMenu .moreInformationMenu {
    position: absolute;
    padding: 1px 8px;
    background-color: var(--dark-dark-two);
    color: white;
    font-size: 14px;
    position: absolute;
    border-radius: 4px;
    margin-left: 58px;
    align-items: center;
    vertical-align: middle;
    display: none;
    z-index: 900;
}

.menusCRM .anchorMenu:hover .moreInformationMenu {
    display: flex;
}

.menusCRM .anchorMenu .moreInformationMenu .numberInformationMenu{
    margin-left: 8px;
    display: inline-flex;
    padding: 0px 4px;
    height: 16px;
    min-width: 16px;
    box-sizing: border-box;
    background: rgba(255, 255, 255, 0.16);
    border-radius: 4px;
    font-weight: 400;
    font-size: 16px;
    font-family: "Source Sans Pro", sans-serif;
    color: #ffffffa3;
    justify-content: center;
    align-items: center;
    text-transform: capitalize;
}

.menusCRM .dividerMenu {
    margin: 8px 12px;
    border: 1px solid rgba(38, 41, 44, 0.64);
    border-radius: 1px;
}

.menusCRM .btnLogout {
    position: absolute;
    bottom: 0;
    background-color: #404346;
}
.crm {
    background-color: #f2f2f2;
    padding: 0;
    margin: 0;
    height: 100vh;
    display: flex;
    flex-direction: row;
}
.screensCRM {
    background-color: transparent;
    width: 100%;
    height: 100%;
    border: none;
}
.leads {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.leads .containerNavigationHeaderLeads {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.leads .containerNavigationHeaderLeads .buttonNavigationHeaderLeads {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.leads .containerNavigationHeaderLeads .buttonNavigationHeaderLeads:hover {
    background-color: var(--white-dark);
}

.leads .containerNavigationHeaderLeads .buttonNavigationHeaderLeads:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.leads .containerNavigationHeaderLeads .buttonNavigationHeaderLeads:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.leads .containerNavigationHeaderLeads .buttonNavigationHeaderLeads.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.leads .containerNavigationHeaderLeads .buttonPlusNavigationHeaderLeads {
    width: 120px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.leads .containerNavigationHeaderLeads .buttonTrashNavigationHeaderLeads {
    font-size: 10px;
    width: 32px;
    height: 32px;
    color: var(--primary-color);
    background-color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
    border: 1px solid var(--white-dark-two);
}

.leads .containerNavigationHeaderLeads .filterFontsHeaderLeads {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.leads .containerNavigationHeaderLeads .filterFontsHeaderLeads .iconFilterFontsHeaderLeads {
    color: red;
    font-size: 14px;
    color: var(--dark-dark);
}

.leads .containerNavigationHeaderLeads .filterFontsHeaderLeads .selectFontHeaderLeads {
    cursor: pointer;
    width: 100%;
    border: none;
}

.leads .containerNavigationHeaderLeads .filterFontsHeaderLeads .selectFontHeaderLeads .textShowSelectMulti {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.leads .containerNavigationHeaderLeads .filterFontsHeaderLeads .selectFontHeaderLeads .fieldSelectMulti {
    border: none;
}
.buttonIcon {
    width: 40px;
    height: 40px;
    outline: none;
    box-shadow: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    position: relative;
    text-align: center;
    background-color: var(--primary-color);
    border-radius: 4px;
    color: var(--white-light);
    font-size: 18px;
}

.buttonIcon:hover { 
    color: var(--primary-color);
    transition: color .3s;
    background-color: var(--dark-light);
    transition: background-color .3s;
}

.dropDown { 
    background-color: var(--white-light);
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    min-width: 100px;
    min-height: 120px;
    position: fixed;
    z-index: 500;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    border-radius: 5px;
    padding: 8px 0;
    color: var(--dark-dark-three);
    font-size: 14px;    
}
.itemHistoric {
    width: 100%;
    padding: 5px 10px;
    background-color: transparent;
    min-height: 60px;
    cursor: pointer;
}

.itemHistoric:hover {
    background-color: var(--white-dark-two);
    transition: background-color 1s;
}

.itemHistoric .colItemHistoric { 
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.itemHistoric .colItemHistoric.detailsItemHistoric {
    display: flex;
    flex-direction: column;
    text-align: left;
    position: relative;    
    overflow: scroll;
}

.itemHistoric .colItemHistoric .iconItemHistoric { 
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.itemHistoric .colItemHistoric .nameItemHistoric {
    color: var(--primary-color);
    font-weight: 600;
    font-size: 16px;
    white-space: nowrap; 
    width: 100%; 
    overflow: hidden;
    text-overflow: ellipsis; 
}

.itemHistoric .colItemHistoric .nameItemHistoric:hover {
    text-decoration: underline;
}

.itemHistoric .colItemHistoric .nameItemHistoric:hover .cardDetailsItemHistoric{
    visibility: visible;
    transition: visibility;
    transition-delay: 1s;
}

.itemHistoric .colItemHistoric .descriptionItemHistoric {
    color: var(--dark-dark);
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 4.2;
    width: 100%;
}

.itemHistoric .colItemHistoric .iconItemHistoric.initialsName { 
    color: var(--color-information);
    font-weight: 600;
    font-size: 10px;
    background-color: #c2dfff;
}
.cardDetailsBusiness {
    position: fixed;
    width: 400px;
    height: 250px;
    z-index: 500;
    color: var(--dark-dark);
    display: flex;
    flex-direction: column;
    top: 120px;
    display: none;
    border-radius: 5px;
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    padding: 30px 0px;
    flex-direction: column;
    display: flex;
    vertical-align: middle;
    background-color: var(--white-light);
    visibility: hidden;
    padding: 10px;
    
}

.cardDetailsBusiness span { 
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
    width: 100%;
} 

.cardDetailsBusiness .lineCardDetailsBusiness {
    width: 100%;
    height: 100%;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    align-items: center;
    height: 100%;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .progressLineDetailsBusiness .stageContentProgressLine {
    width: 19%;
}   

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .progressLineDetailsBusiness .stageContentProgressLine {
   font-size: 0;
   color: transparent;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness.columnIconCardDetailsBusiness {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .anchorNameCardDetailsBusiness {
    font-size: 15px;
    font-weight: 400;
    width: auto;
    width: initial;
    margin-left: 0;
    padding-left: 0;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .anchorNameCardDetailsBusiness:hover {
    color: var(--color-focus);
    text-decoration: underline!important;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .detailItem{    
    font-size: 15px;
    font-weight: 400;
    cursor: default;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .iconCardDetailsBusiness {
    font-size: 14px;
    color: var(--dark-light);
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .iconCardDetailsBusiness.big {
    font-size: 24px;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .moreCardDetailsBusiness {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .moreCardDetailsBusiness .anchorCardDetailsBusiness {
    margin-left: 0;
    padding-left: 0;
    margin-top: 0;
    padding-bottom: 0;
    font-size: 24px;
}

.cardDetailsBusiness .lineCardDetailsBusiness .columnCardDetailsBusiness .moreCardDetailsBusiness .anchorCardDetailsBusiness:hover {
    color: var(--color-focus);
    text-decoration: underline!important;
}

.anchor {
    padding: 10px;
    margin: 5px;
    color: var(--primary-color);
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    text-decoration: none!important;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}
.progressLine {
    width: 100%;
}

.progressLine .lineProgressLine {
    height: 30px; /*DANGER: must be the same height*/
    border-radius: 4px;
}

.progressLine .lineProgressLine .stageContentProgressLine {
    width: 20%;
    height: 100%;
    position: relative;
    background-color: #dedede;
    cursor: pointer;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    color: var(--white-light);
}

.progressLine .lineProgressLine .stageContentProgressLine .infoStageContentProgressLine {
    display: none;
}

.progressLine .lineProgressLine .stageContentProgressLine:hover .infoStageContentProgressLine {
    background-color: black;
    color: white;
    padding: 2px 4px;
    border-radius: 5px;
    position: absolute;
    bottom: -28px;
    min-width: 120px;
    z-index: 600;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    display: block;
}

.progressLine .lineProgressLine .stageContentProgressLine.active {
    background: #08a742;
}

.progressLine .lineProgressLine .stageContentProgressLine .stageArrowProgressLine {
    width: 6px;
    height: 30px; /*DANGER: must be the same height*/
    position: absolute;
    top: 0px;
    left: 0;
    overflow: hidden;
    background-color: #dedede;
    /* color: var(--white-light);
    text-align: center; */
}

.progressLine .lineProgressLine .stageContentProgressLine .stageArrowProgressLine.active {
    background: #08a742;
}

.progressLine .lineProgressLine .stageContentProgressLine .stageArrowProgressLine:after {
    border-top: 4px solid #fff;
    border-right: 4px solid #fff;
    background-color: #dedede;
    width: 24px;
    height: 30px; /*DANGER: must be the same height*/
    position: absolute;
    right: -6px;
    top: 2px;
    border-top: 4px solid #fff;
    border-right: 4px solid #fff;
    -webkit-transform: scaleX(.3) rotate(45deg);
            transform: scaleX(.3) rotate(45deg);
    content: " ";
    cursor: pointer;
}

.progressLine .lineProgressLine .stageContentProgressLine.first .stageArrowProgressLine:after {
    border-top: 4px solid #08a742;
    border-right: 4px solid #08a742;
}

.progressLine .lineProgressLine .stageContentProgressLine.active .stageArrowProgressLine.active:after {
    background: #08a742;
}

.progressLine .lineProgressLine .stageContentProgressLine.last .stageArrowProgressLine:after {
    background: #08a742;
}

.progressLine .lineDetailsProgressLine {
    display: inline-block;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: row;
}

.progressLine .lineDetailsProgressLine span {
    color: var(--primary0color);
    font-size: 12px;
    font-weight: 300;
}

.progressLine .lineDetailsProgressLine .iconProgressLine {
    font-size: 12px;
    color: var(--dark-light);
    margin: 0 5px;
}
.cardDetailsContact {
    position: fixed;
    width: 400px;
    min-height: 40px;
    z-index: 500;
    color: var(--dark-dark);
    display: flex;
    flex-direction: column;
    top: 120px;
    display: none;
    border-radius: 5px;
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    padding: 30px 0px;
    flex-direction: column;
    display: flex;
    vertical-align: middle;
    background-color: var(--white-light);
    visibility: hidden;
    padding: 10px;
}

.cardDetailsContact .lineCardDetailsContact {
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
}

.cardDetailsContact .lineCardDetailsContact .columnIconDetailsContact {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.cardDetailsContact .lineCardDetailsContact .columnIconDetailsContact .initialsNameDetailsContact {
    color: var(--color-information);
    font-weight: 600;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    width: 50px;
    height: 50px;
    background-color: #c2dfff;
    border-radius: 50%;
}

.cardDetailsContact .lineCardDetailsContact .columnDescriptionDetailsContact {
    display: flex;
    align-items: flex-start;
    vertical-align: middle;
    padding-left: 5px;
    overflow: hidden;
    flex-direction: column;
}

.cardDetailsContact .lineCardDetailsContact .columnIconDetailsContact .iconDetailsContact{
    color: var(--dark-dark-three);
    font-size: 12px;
}

.cardDetailsContact .lineCardDetailsContact .columnDescriptionDetailsContact span{
    color: var(--primary-color);
    font-size: 15px;
    font-weight: 400;
}

.cardDetailsContact .lineCardDetailsContact .columnDescriptionDetailsContact .anchorCardDetailsContact {
    white-space: nowrap; 
    width: auto; 
    width: initial;
    overflow: hidden;
    text-overflow: ellipsis; 
    font-size: 20px;
    padding-left: 0;
    margin-left: 0;
}

.cardDetailsContact .lineCardDetailsContact .columnDescriptionDetailsContact .anchorCardDetailsContact:hover {
    color: var(--color-focus);
    text-decoration: underline!important;
}
.inputSearch { 
    width: 100%;
    height: 40px;
    flex-direction: row;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    position: relative;
}

.inputSearch .icon {
    color: var(--primary-color);
    position: absolute;
    left: 14px;
    transition: color .3s;
}

.inputSearch .icon.yesFocus {
    color: green;
    color: var(--color-focus);
}

.inputSearch .icon.noFocus {
    color: var(--primary-color);
}

.inputSearch .field {
    width: 100%;
    height: 40px;
    padding: 5px 45px 6px 36px;
    background-color: var(--white-light);
    border: 2px solid #26292c29;
    border-radius: 40px;
    box-sizing: border-box;
    outline: none!important;
    box-shadow: none;
    color: var(--primary-color);
    transition: border .3s;
}

.inputSearch .field.yesFocus {
    border: 2px solid var(--color-focus);
}

.inputSearch .field.noFocus {
    border: 2px solid #26292c29;
}

.inputSearch .buttonIcon {
    position: absolute;
    right: 20px;
    font-size: 16px;
    height: 24px;
    width: 24px;
    background-color: transparent;
    color: #404346;
}

.inputSearch .buttonIcon:hover {
    background: #26292c0d;
    transition: background-color .1s;
}

.inputSearch .historicSearch {
    position: absolute;
    top: 44px;
    border-radius: 5px;
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    overflow: scroll;
    padding: 30px 0px;
    flex-direction: column;
    display: flex;
    vertical-align: middle;
    width: 350px;
    max-height: 350px;
    background-color: var(--white-light);
    z-index: 900;
}

.inputSearch .historicSearch .labelLastHistoricSearch{  
    font-size: 16px;
    font-weight: 500;
    margin-left: 22px;
    margin-right: 20px;
    margin-bottom: 5px;
    color: var(--dark-dark);
}

@media (max-width: 767px){
    .inputSearch .historicSearch {
        display: none;
    }
}
.header {  
    background-color: var(--white-light);
    z-index: 20;
    box-shadow: rgb(0 0 0 / 5%) 0px 0px 1px 1px, rgb(0 0 0 / 6%) 0px 1px 2px -2px, rgb(0 0 0 / 7%) 0px 1px 3px 0px;
    color: var(--primary-color);
    min-height: 56px;
}

.header .colHeader{  
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.header .colHeader .title{
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 18px;
    margin: 0;
    padding: 0;
    text-align: left;
    font-weight: 600;
    padding: 16px 0px 16px 20px;
}

.header .colHeader .buttonMoreHeader {
    position: relative;
    padding: 0;
    margin: 0;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #08a742;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.1s ease 0s;
    color: var(--white-light);
}

.header .colHeader .buttonMoreHeader:hover {
    background-color: #08a742e0;
    color: var(--white-light);
}

.header .colHeader .buttonProfileHeader {
    border: 4px solid transparent;
    margin-right: 10px;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    overflow: hidden;
    background-color: transparent;
    color: #1372f4;
    font-size: 32px;
}

.header .colHeader .buttonProfileHeader:hover {
    border: 4px solid #e5e5e5;    
    transition: border .2s;
}

.header .colHeader .dropMoreActionsHeader {
}

.header .colHeader .dropMoreActionsHeader .buttonMoreActionsHeader {
    background-color: transparent;
    color: var(--primary-color);
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    justify-content: flex-start;
}

.header .colHeader .dropMoreActionsHeader .buttonMoreActionsHeader .icon{
    margin-right: 15px;
    margin-left: 10px;
    font-size: 18px;
    color: var(--dark-dark-three);
}

.header .colHeader .dropMoreActionsHeader .buttonMoreActionsHeader:hover .icon{
    color: var(--primary-color);
    transition: color  1s;
}

.header .colHeader .dropMoreActionsHeader .buttonMoreActionsHeader:hover {
    background-color: #f2f2f2;
    transition:  background-color 1s;
}

.header .colHeader .dropProfileHeader {
    z-index: 900;
}

.header .colHeader .dropProfileHeader .anchorProfileHeader {
    background-color: transparent;
    color: var(--primary-color);
    font-size: 16px;
    text-align: left;
    font-weight: 400;
    justify-content: flex-start;
    margin: 0;
}

.header .colHeader .dropProfileHeader .anchorProfileHeader .icon {
    margin-right: 15px;
    margin-left: 20px;
    font-size: 18px;
    color: var(--dark-dark-three);
}

.header .colHeader .dropProfileHeader .anchorProfileHeader:hover .icon {
    color: var(--primary-color);
    transition: color  1s;
}

.header .colHeader .dropProfileHeader .anchorProfileHeader:hover {
    background-color: #f2f2f2;
    transition:  background-color 1s;   
}

.header .colHeader .dropProfileHeader .titleProfileHeader {
    color: var(--dark-dark-three);
    margin-top: 14px;
    margin-bottom: 8px;
    margin-left: 30px;
    font-weight: 600;
    font-size: 15px;
    text-transform: uppercase;
}

.header .colHeader .dropProfileHeader .divider {
    height: 1px;
    width: 100%;
    content: " ";
    display: block;
    background-color: var(--white-dark-two);
    margin-top: 10px;
    margin-bottom: 10px;
}
.button {
    box-shadow: none;
    border: none;
    outline: none;
    background-color: green;
    padding: 20px;
    color: #fff;
    background: #08A742;
    font-weight: 700;
    width: 100%;
    padding: 11px 20px;
    text-align: center;
    outline: none;
    white-space: nowrap;
    font-size: 24px;
    line-height: 1.4em;
    font-family: 'Source Sans Pro', sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.button:hover {
    color: #fff;
    background: #26B259;
    transition: background-color 0.3s;
}
.modalGeneral { 
    background-color: rgba(0, 0, 0, 0.294);
    position: absolute;
    height: 100vh;
    width: 100vw;
    z-index: 1200;
    left: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    vertical-align: top;
}

.modalGeneral .contentModalGeneral { 
    background-color: var(--white-light);
    min-width: 300px;
    /* max-width: 800px; */
    border-radius: 5px;
    position: relative;
}

.modalGeneral .contentModalGeneral .lineHeaderModalGeneral {
    display: flex;
    padding: 12px 16px;
    height: 48px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #e5e5e5;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
}

.modalGeneral .contentModalGeneral .lineHeaderModalGeneral .buttonCloseModalGeneral {
    background-color: transparent;
    color: var(--primary-color);
    margin: 0;
    padding: 0;
    width: 30px;
    height: 30px;
}

.modalGeneral .contentModalGeneral .lineHeaderModalGeneral .title {
    font-size: 18px;
    color: #26292c;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.modalGeneral .contentModalGeneral .lineBodyModalGeneral { 
    padding: 13px 15px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.modalGeneral .contentModalGeneral .lineFooterModalGeneral {
    display: flex;
    height: 48px;
    background-color: #f7f7f7;
    border-top: 1px solid #e5e5e5;
    width: 100%;
    align-items: flex-end;
    padding: 12px 48px 12px 16px;

}

.modalGeneral .contentModalGeneral .lineFooterModalGeneral .buttonModalGeneral { 
    /* margin: 0;
    min-width: 60px;
    max-width: 120px;
    height: 40px;
    font-size: 16px; */
}
.groupInputGeneral {  
    width: 100%;
}

.groupInputGeneral .labelInputGeneral {  
    color: #747678;
    font: 400 15px/20px 'Source Sans Pro', sans-serif;
    margin-bottom: 4px;
}

.groupInputGeneral .inputInputGeneral {  
    position: relative;
}

.groupInputGeneral .inputInputGeneral .fieldInputGeneral {  
    width: 100%;
    border-radius: 2px;
    border: 1px solid #cbcccd;
    box-shadow: inset 0 1px 2px #eee;
    box-sizing: border-box;
    display: block;
    padding: 4px 32px 4px 8px;
    height: 32px;
    font: inherit;
    color: #26292c;
    transition: padding 75ms ease-in-out;
    
}

.groupInputGeneral .inputInputGeneral .fieldInputGeneral.error { 
    border: 1px solid var(--color-error);
}

.groupInputGeneral .inputInputGeneral .fieldInputGeneral:focus {
    border: 1px solid #317ae2;
    box-shadow: inset 0 1px 2px rgb(49 122 226 / 8%);
    outline: none;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral {
    width: 32px;
    height: 32px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    padding: 6px;
    bottom: 0;
    right: 0;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .buttonInformationInputGeneral { 
    background-color: transparent;
    color: var(--color-warning);
    height: 100%;
    z-index: 600;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .contentWarningSearchInputGeneral { 
    background-color: var(--white-light);
    min-width: 280px;
    height: 300px;
    position: absolute;
    top: 0;
    left: 34px;
    z-index: 600;
    flex-direction: column;
    display: flex;
    box-shadow: 0px 1px 5px var(--dark-light);
    border: 1px solid #cbcccd;
    border-radius: 2px;
    overflow: scroll;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .contentWarningSearchInputGeneral .headerContentWarningInputGeneral { 
    display: flex;
    padding: 12px 16px;
    height: 48px;
    background-color: #f7f7f7;
    border-bottom: 1px solid #e5e5e5;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .contentWarningSearchInputGeneral .headerContentWarningInputGeneral .title { 
    font-size: 18px;
    color: #26292c;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .contentWarningSearchInputGeneral .headerContentWarningInputGeneral .buttonCloseContentWarningInputGeneal { 
    background-color: transparent;
    color: var(--primary-color);
    margin: 0;
    padding: 0;
    width: 30px;
    height: 30px;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .contentWarningSearchInputGeneral .bodyContentWarningInputGeneral {
    height: 100%;
    flex-direction: column;
    display: flex;
    padding: 10px;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .contentWarningSearchInputGeneral .bodyContentWarningInputGeneral .notice {
    border-bottom: 1px solid var(--white-dark-two);
    padding: 10px;
    cursor: pointer;
    font-weight: 400;
    flex-direction: column;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    vertical-align: middle;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .contentWarningSearchInputGeneral .bodyContentWarningInputGeneral .notice:hover {
    background-color: var(--white-dark);
    font-weight: 600;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .contentWarningSearchInputGeneral .bodyContentWarningInputGeneral .notice .label {
    font-weight: 300;
}

.groupInputGeneral .inputInputGeneral .moreInformationSearchInputGeneral .contentWarningSearchInputGeneral .bodyContentWarningInputGeneral .notice .input {
    color: var(--color-focus);
}

.personForm {
    width: 100%;
}

.personForm .linePersonForm { 
    margin-bottom: 5px;
    display: flex;
    align-items: flex-end;
}

.personForm .linePersonForm .columnPersonForm {
    height: 100%;
}

.personForm .linePersonForm .columnPersonForm .mandatoryInformation {
    font-size: 12px;
    color: #747678;
}

.personForm .lineFooterPersonForm {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
}

.personForm .lineFooterPersonForm .buttonPersonForm {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.personForm .lineFooterPersonForm .buttonPersonForm:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.personForm .lineFooterPersonForm .buttonPersonForm.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.personForm .lineFooterPersonForm .buttonPersonForm.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}
.optionSelect {
    background-color: var(--white-light);
    color: var(--primary-color);
    text-align: left;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
    font-weight: 400;
    overflow: hidden;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: space-between;
}

.optionSelect.active {
    font-weight: 600;
}

.optionSelect:hover {
    background-color: var(--color-focus);
    color: var(--white-light);
}

.optionSelect .labelOptionSelect {
}

.optionSelect .labelOptionSelect.lead {
    color: var(--white-light);
    font-size: 11px;
    font-weight: 600;
    padding: 1px 6px;
    border-radius: 2px;

}

.optionSelect .labelOptionSelect.error {
    background-color: var(--color-error);
}

.optionSelect .labelOptionSelect.warning {
    background-color: var(--color-warning);
    color: #000;
}

.optionSelect .labelOptionSelect.focus {
    background-color: var(--color-focus);
}

.optionSelect .iconLabelSelectedOptionSelect {
    color: var(--color-focus);

}

.optionSelect:hover .iconLabelSelectedOptionSelect {
    color: var(--white-light);
}
.select { 
    position: relative;
    min-width: 100px;
}

.select .labelSelect { 
    color: #747678;
    font: 400 15px/20px 'Source Sans Pro', sans-serif;
    margin-bottom: 4px;
}

.select .fieldSelect .lead {
    color: var(--white-light);
    font-size: 11px;
    font-weight: 600;
    padding: 1px 6px;
    border-radius: 2px;

}

.select .fieldSelect .lead.error {
    background-color: var(--color-error);
}

.select .fieldSelect .lead.warning {
    background-color: var(--color-warning);
    color: #000;
}

.select .fieldSelect .lead.focus {
    background-color: var(--color-focus);
}

.select .fieldSelect {
    border-radius: 2px;
    border: 1px solid #cbcccd;
    box-shadow: inset 0 1px 2px #eee;
    padding: 4px 8px;
    height: 32px;
    color: #26292c;
    transition: padding 75ms ease-in-out;
    display: flex;
    align-items: center;
    vertical-align: middle;
    position: relative;
}

.select .fieldSelect .iconShowSelect{
    position: absolute;
    font-size: 14px;
    right: 10px;
    color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    margin: 0;
}

.select .optionsSelect {
    z-index: 700;
    padding-top: 5px;
    padding-bottom: 5px;
    position: absolute;
    width: 100%;
    min-height: 100px;
    margin-top: 5px;
    border-radius: 5px;
    background-color: var(--white-light);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 16%), 0 3px 4px 0 rgb(0 0 0 / 5%), 0 3px 3px -2px rgb(0 0 0 / 6%), 0 1px 8px 0 rgb(0 0 0 / 10%);
}
.selectMulti { 
    position: relative;
    min-width: 100px;
}

.selectMulti .labelSelectMulti { 
    color: #747678;
    font: 400 15px/20px 'Source Sans Pro', sans-serif;
    margin-bottom: 4px;
}

.selectMulti .fieldSelectMulti .lead {
    color: var(--white-light);
    font-size: 11px;
    font-weight: 600;
    padding: 1px 6px;
    border-radius: 2px;

}

.selectMulti .fieldSelectMulti .lead.error {
    background-color: var(--color-error);
}

.selectMulti .fieldSelectMulti .lead.warning {
    background-color: var(--color-warning);
    color: #000;
}

.selectMulti .fieldSelectMulti .lead.focus {
    background-color: var(--color-focus);
}

.selectMulti .fieldSelectMulti {
    border-radius: 2px;
    border: 1px solid #cbcccd;
    box-shadow: inset 0 1px 2px #eee;
    padding: 4px 8px;
    height: 32px;
    color: #26292c;
    transition: padding 75ms ease-in-out;
    display: flex;
    align-items: center;
    vertical-align: middle;
    position: relative;
}

.selectMulti .fieldSelectMulti .iconShowSelectMulti{
    position: absolute;
    font-size: 14px;
    right: 10px;
    color: var(--primary-color);
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    margin: 0;
}

.selectMulti .optionsSelectMulti {
    z-index: 700;
    padding-top: 5px;
    padding-bottom: 5px;
    position: absolute;
    width: 100%;
    min-height: 100px;
    margin-top: 5px;
    border-radius: 5px;
    background-color: var(--white-light);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    box-shadow: 0 0 2px 0 rgb(0 0 0 / 16%), 0 3px 4px 0 rgb(0 0 0 / 5%), 0 3px 3px -2px rgb(0 0 0 / 6%), 0 1px 8px 0 rgb(0 0 0 / 10%);
}

.selectMulti .optionsSelectMulti .active {
    background-color: var(--color-focus);
    color: var(--white-light);
}

.selectMulti .optionsSelectMulti .iconLabelSelectedOptionSelect {
    color: var(--white-light);    
}
.businessForm {
    width: 100%;
}

.businessForm .lineBusinessForm { 
    margin-bottom: 5px;
    display: flex;
    align-items: flex-end;
}

.businessForm .lineBusinessForm .columnBusinessForm {
    height: 100%;
}

.businessForm .lineBusinessForm .columnBusinessForm .progressLineBusinessForm .stageContentProgressLine {
    font-size: 0;
    color: transparent;
}

.businessForm .lineBusinessForm .columnBusinessForm .mandatoryInformation {
    font-size: 12px;
    color: #747678;
}

.businessForm .lineBusinessForm .columnBusinessForm .selectStampsBusinessForm {
}

.businessForm .lineBusinessForm .columnBusinessForm .selectStampsBusinessForm .labelOptionSelect {
}

.businessForm .lineFooterBusinessForm {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
}

.businessForm .lineFooterBusinessForm .buttonBusinessForm {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.businessForm .lineFooterBusinessForm .buttonBusinessForm:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.businessForm .lineFooterBusinessForm .buttonBusinessForm.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.businessForm .lineFooterBusinessForm .buttonBusinessForm.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}

.businessForm .lineBusinessForm .columnBusinessForm .stepBusinessForm {
    color: #747678;
    font: 400 15px/20px 'Source Sans Pro', sans-serif;
    margin-bottom: 4px;
}
.menus {
    display: inline-flex;
    flex-wrap: wrap;
    width: 100%;
    border-bottom: 1px solid var(--white-dark-two);
}

.menus .buttonMenus {
    width: auto;
    font-size: 16px;
    font-weight: 600;
    background-color: transparent;
    color: var(--dark-dark-three);
    padding: 10px 13px;
    height: 47px;
    
}

.menus .buttonMenus:hover {
    background-color: var(--white-dark);
    color: var(--primary-color);
}

.menus .buttonMenus.active {
    color: var(--color-focus);
    border-bottom: 2px solid var(--color-focus);
    background-color: #eff5fd;
}

.menus .buttonMenus:hover.active {
    background: #d3e4fa;
}

.menus .buttonMenus .icon {
    margin-right: 10px;
}
.activityForm { 
    padding: 0;
}

.activityForm .bodyActivityForm { 
    padding: 16px 36px;
}

.activityForm .bodyActivityForm .labelSuccessServiceActivityForm {
    color: var(--color-success);
    font-weight: 600;
    font-size: 16px;
    display: inline-block;
    width: 100%;
    
}

.activityForm .bodyActivityForm .labelErrorServiceActivityForm {
    color: var(--color-error);
    font-weight: 600;
    font-size: 16px;
    width: 100%;
    display: inline-block;
}

.activityForm .bodyActivityForm .labeDetailsServiceActivityForm {
    color: var(--color-warning);
    font-weight: 400;
    font-size: 14px;
    
}

.activityForm .bodyActivityForm .valueAuthorizationSuccessAcitivityForm input {
    background-color: #28a74678;
}

.activityForm .bodyActivityForm .valueAuthorizationWarningAcitivityForm input {
    background-color: #ffc1075e;
}

.activityForm .bodyActivityForm .valueAuthorizationErrorAcitivityForm input {
    background-color: #dc35465b;
}

.activityForm .bodyActivityForm .subtitleGeneralActivityForm {
    color: #747678;
    font: 400 15px/20px 'Source Sans Pro', sans-serif;
    margin-bottom: 4px;
}

.activityForm .bodyActivityForm .subtitleSuccessActivityForm,
.activityForm .bodyActivityForm .subtitleWarningActivityForm,
.activityForm .bodyActivityForm .subtitleErrorActivityForm {
    color: #747678;
    font: 400 15px/20px 'Source Sans Pro', sans-serif;
    margin-bottom: 4px;
    display: inline-flex;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
    width: 100%;
}

.activityForm .bodyActivityForm .subtitleSuccessActivityForm:before,
.activityForm .bodyActivityForm .subtitleWarningActivityForm:before,
.activityForm .bodyActivityForm .subtitleErrorActivityForm:before {
    display: block;
    content: " ";
    width: 10px;
    height: 10px;
    background-color: var(--primary-color);
    border-radius: 1px;
    margin-right: 5px;
}

.activityForm .bodyActivityForm .subtitleSuccessActivityForm:before {
    background-color: #28a74678;
}

.activityForm .bodyActivityForm .subtitleWarningActivityForm:before {
    background-color: #ffc1075e;
}

.activityForm .bodyActivityForm .subtitleErrorActivityForm:before {
    background-color: #dc35465b;
}

.activityForm .bodyActivityForm .lineActivityForm {
    margin-top: 10px;
    margin-bottom: 10px;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .nameActivityForm input {
    height: 40px!important;
    margin: 0;
    font-size: 22px!important;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .menusActivityForm {
    border: none;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .menusActivityForm button {
    height: 32px;
    font-size: 14px;
    border-left: 1px solid var(--white-dark-two);
    border-top: 1px solid var(--white-dark-two);
    border-bottom: 1px solid var(--white-dark-two);
    color: var(--primary-color);    
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .menusActivityForm button:first-of-type {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .menusActivityForm button:last-of-type {
    border-right: 1px solid var(--white-dark-two);
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .menusActivityForm button.active {
    border: 1px solid var(--color-focus);
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm {
    position: relative;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .dropdownTimeActivityForm {
    position: absolute;
    top: 0;
    left: 0;
    top: inherit;
    flex-direction: column;
    width: 100%;
    z-index: 900;
    max-height: 200px;
    overflow: scroll;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .timeActivityForm {
    padding: 5px 15px;
    font-weight: 400;
    cursor: pointer;
    display: flex;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .buttonClearTimeActivityForm {
    position: absolute;
    top: 0;
    width: 32px;
    height: 32px;
    right: 10px;
    background-color: transparent;
    color: var(--primary-color);
    font-size: 10px;

}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .timeActivityForm:hover,
.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .timeActivityForm.active {
    background-color: var(--color-focus);
    color: var(--white-light);
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .annotationsActivityForm textarea{
    height: 150px!important;
    background: rgb(255, 252, 220)!important;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .buttonFileActivityForm {
    background-color: transparent;
    color: var(--dark-dark-three);    
    margin-right: 80px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm.file {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .buttonFileActivityForm::after {
    content: "Arquivo";
    display: block;
    position: absolute;
    right: -70px;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .buttonFileActivityForm:hover {
    background-color: var(--dark-dark-three);
    color: var(--white-light);
    border-radius: 50%;
}

.activityForm .bodyActivityForm .lineActivityForm .columnActivityForm .nameFileActivityForm {
    color: var(--dark-dark-three);    
    font-weight: 300;
}

.activityForm .footerActivityForm .lineActivityForm {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 36px;
    padding-right: 36px;
    background: rgb(247, 247, 247);
    border-top: 1px solid rgb(203, 204, 205);
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}

.activityForm .footerActivityForm .lineActivityForm .checkTaskActivityForm {
    width: auto;
    width: initial;
    margin-right: 15px;
    border-radius: 2px!important;
    color: var(--primary-color);
}

.activityForm .footerActivityForm .lineActivityForm .checkTaskActivityForm .squareCheck {
    border-radius: 4px;
}

.activityForm .footerActivityForm .lineActivityForm .checkTaskActivityForm.active .squareCheck {
    background-color: var(--color-focus);
    border-color: var(--color-focus);
}

.activityForm .footerActivityForm .lineActivityForm .checkTaskActivityForm:hover .squareCheck {
    border-color: var(--color-focus);
}

.activityForm .footerActivityForm .lineActivityForm .checkTaskActivityForm.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.activityForm .footerActivityForm .lineActivityForm .buttonActivityForm {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.activityForm .footerActivityForm .lineActivityForm .buttonActivityForm:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.activityForm .footerActivityForm .lineActivityForm .buttonActivityForm.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
    margin-left: 10px;
}

.activityForm .footerActivityForm .lineActivityForm .buttonActivityForm.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}
.groupInputArea {  
    width: 100%;
}

.groupInputArea .labelInputArea {  
    color: #747678;
    font: 400 15px/20px 'Source Sans Pro', sans-serif;
    margin-bottom: 4px;
}

.groupInputArea .inputInputArea {  
    position: relative;
}

.groupInputArea .inputInputArea .fieldInputArea {  
    width: 100%;
    border-radius: 2px;
    border: 1px solid #cbcccd;
    box-shadow: inset 0 1px 2px #eee;
    box-sizing: border-box;
    display: block;
    padding: 4px 32px 4px 8px;
    height: 32px;
    font: inherit;
    color: #26292c;
    transition: padding 75ms ease-in-out;
    
}

.groupInputArea .inputInputArea .fieldInputArea.error { 
    border: 1px solid var(--color-error);
}

.groupInputArea .inputInputArea .fieldInputArea:focus {
    border: 1px solid #317ae2;
    box-shadow: inset 0 1px 2px rgb(49 122 226 / 8%);
    outline: none;
}
.message {
    background-color: rgba(0, 0, 0, 0.342);
}

.modal-content { /* bootstrap class */
    background-color: transparent;
    border: none;
}

.message .content {
    border-radius: 20px;
    background-color: var(--white-light);
    overflow: hidden;
}

/* Start styling header */
/* Start styling header */
/* Start styling header */

.message .content .header {
    border-bottom: 2px solid var(--dark-dark);
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    padding: 30px;
}

.message .content .header .status{
    font-weight: 600;
    font-size: 16px;
}

.message .content .header .status.success{ color: #28a745 }

.message .content .header .status.error{ color: #dc3545 }

.message .content .header .status.warning{ color: #ffc107 }

.message .content .header .status.information{ color: #007bff }

.message .content .header .status .iconStatus{
    margin-right: 10px;
    font-size: 22x;
}

.message .content .header .close{
    border: 1px solid var(--dark-dark);
    padding: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: transparent!important;
}
.message .content .header .button .iconButton{
    color: var(--dark-dark);
}

/* Ends styling header */
/* Ends styling header */
/* Ends styling header */

/* Start styling body */
/* Start styling body */
/* Start styling body */

.message .content .body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    text-align: justify;
    padding: 30px;
}

.message .content .body p {
   font-size: 18px;
   font-weight: 400;
   color: var(--dark-dark);
}

/* Ends styling body */
/* Ends styling body */
/* Ends styling body */
.ownerForm {
    width: 100%;
}

.ownerForm .lineOwnerForm { 
    margin-bottom: 5px;
    display: flex;
    align-items: flex-end;
}

.ownerForm .lineOwnerForm .columnOwnerForm {
    height: 100%;
}

.ownerForm .lineOwnerForm .columnOwnerForm .mandatoryInformation {
    font-size: 12px;
    color: #747678;
}

.ownerForm .lineFooterOwnerForm {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
}

.ownerForm .lineFooterOwnerForm .buttonOwnerForm {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.ownerForm .lineFooterOwnerForm .buttonOwnerForm:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.ownerForm .lineFooterOwnerForm .buttonOwnerForm.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.ownerForm .lineFooterOwnerForm .buttonOwnerForm.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}
.permissionGroupForm {
    width: 100%;
}

.permissionGroupForm .linePermissionGroupForm { 
    margin-bottom: 5px;
    display: flex;
    align-items: flex-end;
}

.permissionGroupForm .linePermissionGroupForm .columnPermissionGroupForm {
    height: 100%;
}

.permissionGroupForm .linePermissionGroupForm .columnPermissionGroupForm .mandatoryInformation {
    font-size: 12px;
    color: #747678;
}

.permissionGroupForm .lineFooterPermissionGroupForm {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
}

.permissionGroupForm .lineFooterPermissionGroupForm .buttonPermissionGroupForm {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.permissionGroupForm .lineFooterPermissionGroupForm .buttonPermissionGroupForm:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.permissionGroupForm .lineFooterPermissionGroupForm .buttonPermissionGroupForm.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.permissionGroupForm .lineFooterPermissionGroupForm .buttonPermissionGroupForm.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}
.unitCompanyForm {
    width: 100%;
}

.unitCompanyForm .lineUnitCompanyForm { 
    margin-bottom: 5px;
    display: flex;
    align-items: flex-end;
}

.unitCompanyForm .lineUnitCompanyForm .columnUnitCompanyForm {
    height: 100%;
}

.unitCompanyForm .lineUnitCompanyForm .columnUnitCompanyForm .mandatoryInformation {
    font-size: 12px;
    color: #747678;
}

.unitCompanyForm .lineFooterUnitCompanyForm {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
}

.unitCompanyForm .lineFooterUnitCompanyForm .buttonUnitCompanyForm {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.unitCompanyForm .lineFooterUnitCompanyForm .buttonUnitCompanyForm:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.unitCompanyForm .lineFooterUnitCompanyForm .buttonUnitCompanyForm.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.unitCompanyForm .lineFooterUnitCompanyForm .buttonUnitCompanyForm.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}
.commissionRuleForm {
    width: 100%;
}

.commissionRuleForm .lineCommissionRuleForm { 
    margin-bottom: 5px;
    display: flex;
    align-items: flex-end;
}

.commissionRuleForm .lineCommissionRuleForm .columnCommissionRuleForm {
    height: 100%;
}

.commissionRuleForm .lineCommissionRuleForm .columnCommissionRuleForm .mandatoryInformation {
    font-size: 12px;
    color: #747678;
}

.commissionRuleForm .lineFooterCommissionRuleForm {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
}

.commissionRuleForm .lineFooterCommissionRuleForm .buttonCommissionRuleForm {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.commissionRuleForm .lineFooterCommissionRuleForm .buttonCommissionRuleForm:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.commissionRuleForm .lineFooterCommissionRuleForm .buttonCommissionRuleForm.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.commissionRuleForm .lineFooterCommissionRuleForm .buttonCommissionRuleForm.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}
.fieldEdit {
    border: 1px solid #eee;
    /* border-left: 1px solid transparent; */ /*optional*/
    /* border-bottom: 1px solid transparent; */ /*optional*/
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    padding-left: 10px;
    align-items: center;
}

.fieldEdit .iconFieldEdit {
    color: var(--primary-color);
    padding-right: 10px;
}

.fieldEdit:hover {
    background-color: var(--white-light);
}

.fieldEdit .buttonFieldEdit {
    height: 28px;
    width: 28px;
    background-color: var(--white-light);
    font-size: 12px;
    border: 1px solid var(--dark-light);
    right: 2px;
    top: 1px;
    float: right;
    color: var(--primary-color);
    position: absolute;
    opacity: 0;
}

.fieldEdit:hover .buttonFieldEdit {
    opacity: 1;
}

.fieldEdit .modalFieldEdit {
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: var(--white-light);
    z-index: 700;
    /* max-width: 300px; */
    /* max-height: 130px; */
}
.modalEdit {
    padding: 0;
    margin: 0;
    background-color: var(--white-light);
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 5px;
    background-color: var(--white-light);
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    position: relative;
    /* overflow: hidden; */
}

.modalEdit .headerModalEdit {
    padding: 15px;
}

.modalEdit .headerModalEdit .titleModalEdit {
    color: var(--dark-light);
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.modalEdit .bodyModalEdit {
    padding: 15px;
    padding-top: 0;
}

.modalEdit .footerModalEdit {
    border-top: 1px solid var(--white-dark-two);
    background-color: var( --white-dark);
    height: 100%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    padding: 0 15px;
}

.modalEdit .footerModalEdit .buttonModalEdit {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 80px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
    /* width: 50px; */
}

.modalEdit .footerModalEdit .buttonModalEdit:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.modalEdit .footerModalEdit .buttonModalEdit.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.modalEdit .footerModalEdit .buttonModalEdit.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}
.tableEditLeads {
    background-color: var(--white-light);
}

.tableEditLeads .helpTableEditLeads {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;

}

.tableEditLeads table, tr, th, td {
    margin: 0;
}

.tableEditLeads table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditLeads table .buttonCheckTableEditLeads {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.tableEditLeads table .buttonCheckTableEditLeads .squareCheck {
    border: 2px solid var(--white-dark-two);
    margin: 0;
    border-radius: 2px;
}

.tableEditLeads table .buttonCheckTableEditLeads.active .squareCheck {
    background-color: var(--color-focus);
    border: 2px solid var(--color-focus);
}

.tableEditLeads table .buttonCheckTableEditLeads.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.tableEditLeads table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
  
}

.tableEditLeads table tr {
    height: 32px;
}

.tableEditLeads .theadTableEditLeads td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
}

.tableEditLeads .theadTableEditLeads td:last-of-type {
    border-right: none;
}

.tableEditLeads td.checkTableEditLeads {
    text-align: center;
    vertical-align: middle;
    width: 35px!important;
    padding: 0;
}

.tableEditLeads .tbodyTableEditLeads:hover {
    background-color: var(--white-dark);
}

.tableEditLeads .tbodyTableEditLeads td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
}

.tableEditLeads .table .tbodyTableEditLeads td.checkTableEditLeads {
    border: 1px solid #eee;
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
}

.tableEditLeads .tbodyTableEditLeads td:hover .buttonEditTableEditLeads {
    opacity: 1;
}

.tableEditLeads .tbodyTableEditLeads td:hover .editTableEditLeads {    
    border: 1px solid var(--color-focus)!important;
}

.tableEditLeads .tbodyTableEditLeads td .editTableEditLeads.noEdit .buttonFieldEdit {
    display: none;
}

.tableEditLeads .tbodyTableEditLeads td .stampEditLeads {
    position: absolute;
    z-index: 900;
    height: 20px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    font-size: 11px;
    padding: 1px 4px;
    border-radius: 2px;
    color: var(--white-light);
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 8px;
    text-align: center;
    color: var(--white-light);

}

.tableEditLeads .tbodyTableEditLeads td .editTableEditLeads .modalFieldEdit {
    z-index: 1000;
}
.check {
    position: relative;
    flex-direction: row;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
    background-color: transparent;
    outline: none;
    border: none;
    box-shadow: none;
    cursor: pointer;
    text-align: left;
    color: #26292ca3;
    font-size: 15px;
    line-height: 20px;    
}

.check .squareCheck {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    margin-right: 10px;
    border: 1px solid black;
    padding: 8px;
    width: 16px;
    height: 16px;
    border: 2px solid #b9babb;
}

.check .iconCheck{
    color: #000;
    font-size: 10px;
}
.business {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.business .headerBusiness {
}

.business .warningBusiness {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.business .containerDraggableAreaHeaderBusiness {
    display: flex;
    flex-direction: row;
    height: 60px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.business .containerDraggableAreaHeaderBusiness .colDraggableAreaHeaderBusines {  
    position: relative;
    width: 20%;
    background-color: var(--white-dark);
    display: flex;
    flex-direction: column;
    justify-content: center;
    vertical-align: middle;
    text-align: left;
    padding-left: 15px;
}

.business .containerDraggableAreaHeaderBusiness .colDraggableAreaHeaderBusines .titleColumnBusiness { 
    color: var(--primary-color);
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.business .containerDraggableAreaHeaderBusiness .colDraggableAreaHeaderBusines .informationColumnBusiness { 
    color:  var(--dark-light);
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.business .containerDraggableAreaHeaderBusiness .colDraggableAreaHeaderBusines .arrow{
    width: 9px;
    height: 55px;
    position: absolute;
    right: -4px;
    fill: var(--white-light);
    z-index: 600;
}

.business .containerDraggableAreBusiness { 
    background-color: var(--white-dark);
    position: relative;
    flex-direction: row;
    display: flex;
    height: calc(100vh - 116px - 65px); /*56px by header component + 60px by containerDraggableAreaHeaderBusiness + 65px containerNavigationHeaderBusiness*/
    overflow: scroll;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness { 
    width: 20%;
    height: 100%;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness.active {
    background-color: var(--white-dark-two);
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .draggableBusiness { 
    width: 500px;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .buttonPlusDealDraggableBusiness {
    opacity: 0; 
    background-color: transparent;
    margin: 5px 10px;
    height: 30px;
    border-radius: 5px;
    font-size: 18px;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness:hover .buttonPlusDealDraggableBusiness {
    opacity: 1;
    color: var(--dark-light);
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .buttonPlusDealDraggableBusiness:hover {
    background: rgba(38,41,44,.05);
    color: var(--primary-color);
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .containerDraggableBusiness { 
    height: 100%;
    width: 100%;
    padding: 6px 10px;
    display: flex;
    flex-direction: column;
    line-height: 1.3;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .containerDraggableBusiness .containerStampDraggableBusiness {
    background-color: black;
    width: 32px!important;
    border-radius: 10px!important;
    height: 4px!important;
    overflow: hidden;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .containerDraggableBusiness .containerStampDraggableBusiness .stampDraggableBusiness {
    display: block;
    content: " ";
    width: 100%;
    height: 100%;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .containerDraggableBusiness .titleDraggableBusiness {
    color: var(--primary-color);
    font-weight: 600;
    font-size: 16px;
    margin: 0;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .containerDraggableBusiness .nameDraggableBusiness {
    color: var(--dark-light);
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
}

.business .containerDraggableAreBusiness .columnDraggableAreaBusiness .containerDraggableBusiness .moneyDraggableBusiness {
    color: var(--dark-light);
    font-size: 14px;
    font-weight: 600;

}

.business .containerDraggableAreaFooterBusiness {
    border: 1px solid var(--white-dark-two);
    background-color: var(--white-dark);
    height: 65px;
    position: absolute;
    bottom: -65px;
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 5px;
    z-index: 400;
    transition: bottom 1.1s;
}

.business .containerDraggableAreaFooterBusiness.active {
    bottom: 0px;
    transition: bottom 1.1s;
}

.business .containerDraggableAreaFooterBusiness .columnDraggableAreaFooterBusiness {
    width: 25%;
    padding: 5px;
}

.business .containerDraggableAreaFooterBusiness .columnDraggableAreaFooterBusiness .buttonDraggableFooterBusiness {
    height: 100%;
    width: 100%;
    font-size: 16px;
    background-color: transparent;
    font-weight: 600;
    color: var(--dark-light);
    text-transform: uppercase;
    border: 1px dashed var(--dark-light);
}

.business .containerDraggableAreaFooterBusiness .columnDraggableAreaFooterBusiness .buttonDraggableFooterBusiness:hover {
    border: none;
    background-color: var(--white-dark-two);
}

.business .containerDraggableAreaFooterBusiness .columnDraggableAreaFooterBusiness .buttonDraggableFooterBusiness.error {
    color: var(--color-error);
}

.business .containerDraggableAreaFooterBusiness .columnDraggableAreaFooterBusiness .buttonDraggableFooterBusiness.error:hover {
    border: none;
    color: var(--white-light);
    background-color: var(--color-error);
}

.business .containerDraggableAreaFooterBusiness .columnDraggableAreaFooterBusiness .buttonDraggableFooterBusiness.success {
    color: var(--color-success);
}

.business .containerDraggableAreaFooterBusiness .columnDraggableAreaFooterBusiness .buttonDraggableFooterBusiness.success:hover {
    border: none;
    color: var(--white-light);
    background-color: var(--color-success);
}

.business .containerNavigationHeaderBusiness {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.business .containerNavigationHeaderBusiness .buttonNavigationHeaderBusiness {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.business .containerNavigationHeaderBusiness .buttonNavigationHeaderBusiness:hover {
    background-color: var(--white-dark);
}

.business .containerNavigationHeaderBusiness .buttonNavigationHeaderBusiness:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.business .containerNavigationHeaderBusiness .buttonNavigationHeaderBusiness:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.business .containerNavigationHeaderBusiness .buttonNavigationHeaderBusiness.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.business .containerNavigationHeaderBusiness .buttonPlusNavigationHeaderBusiness {
    width: 100px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.business .containerNavigationHeaderBusiness .buttonTrashNavigationHeaderBusiness {
    font-size: 10px;
    width: 32px;
    height: 32px;
    color: var(--primary-color);
    background-color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
    border: 1px solid var(--white-dark-two);
}
.draggable {  
	cursor: pointer;
	border: none;
	min-width: 50px;
	min-height: 80px;
	background-color: var(--white-light);
	border-radius: 2px;
	display: flex;
	justify-content: center;
	align-items: center;
	vertical-align: middle;
	color: var(--primary-color);
	margin: 5px 10px;
	border: 1px solid var(--white-dark-two);
}

.draggable:hover {
	box-shadow: rgb(0 0 0 / 16%) 0px 0px 1px 0px, rgb(0 0 0 / 5%) 0px 2px 2px 0px, rgb(0 0 0 / 6%) 0px 3px 1px -2px, rgb(0 0 0 / 10%) 0px 1px 5px 0px;
	transition: box-shadow 1s;
}
.tableEditBusiness {
    background-color: var(--white-light);
}

.tableEditBusiness .helpTableEditBusiness {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;

}

.tableEditBusiness table, tr, th, td {
    margin: 0;
}

.tableEditBusiness table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditBusiness table .buttonCheckTableEditBusiness {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}


.tableEditBusiness table .buttonCheckTableEditBusiness .squareCheck {
    border: 2px solid var(--white-dark-two);
    margin: 0;
    border-radius: 2px;
}

.tableEditBusiness table .buttonCheckTableEditBusiness.active .squareCheck {
    background-color: var(--color-focus);
    border: 2px solid var(--color-focus);
}

.tableEditBusiness table .buttonCheckTableEditBusiness.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.tableEditBusiness table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
}

.tableEditBusiness table tr {
    height: 32px;
}

.tableEditBusiness .theadTableEditBusiness td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
}

.tableEditBusiness .theadTableEditBusiness td:last-of-type {
    border-right: none;
}

.tableEditBusiness td.checkTableEditBusiness {
    text-align: center;
    vertical-align: middle;
    width: 35px!important;
    padding: 0;
}

.tableEditBusiness .tbodyTableEditBusiness:hover {
    background-color: var(--white-dark);
}

.tableEditBusiness .tbodyTableEditBusiness td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
}

.tableEditBusiness .table .tbodyTableEditBusiness td.checkTableEditBusiness {
    border: 1px solid #eee;
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
}

.tableEditBusiness .tbodyTableEditBusiness td:hover .buttonEditTableEditBusiness {
    opacity: 1;
}

.tableEditBusiness .tbodyTableEditBusiness td:hover .editTableEditBusiness {    
    border: 1px solid var(--color-focus)!important;
}
.detailsBusiness {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.detailsBusiness .warningDetailsBusiness {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.detailsBusiness .headerNavigationDetailsBusiness {
}

.detailsBusiness .scrollDetailsBusiness {
    overflow: scroll;
    height: 100%;
}

/* start styles header */
/* start styles header */
/* start styles header */
.detailsBusiness .headerDetailsBusiness {
    background-color: var(--white-light);
    padding: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid #e5e5e5;
    margin-top: 2px;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness {
    margin-bottom: 16px;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness {
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .modalEditTitleDetailsBusiness {
    position: absolute;
    top: 30px;
    width: 300px;
    height: 140px;
    z-index: 800;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .modalMoreActionsDetailsBusiness {
    position: absolute;
    top: 50px;
    z-index: 800;
    width: 250px;
    min-height: 120px;
    height: 100%;
    padding: 0;
    margin: 0;
    background-color: var(--white-light);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    padding-top: 10px;
    padding-bottom: 10px;
    border: 0.1px solid var(--white-dark-two);
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .modalMoreActionsDetailsBusiness::after {
    width: 18px;
    height: 18px;
    background-color: var(--white-light);
    display: block;
    content: " ";
    position: absolute;
    top: -9px;
    right: 10px;
    z-index: 700;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    border: none;
    border-left: 1px solid var(--white-dark-two);
    border-bottom: 1px solid var(--white-dark-two);
    /* box-shadow: 0px 0px 7px 1px var(--white-dark-two); */
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .modalMoreActionsDetailsBusiness .buttonMoreActionsDetailsBusiness {
    border: none;
    font-size: 15px;
    background-color: transparent;
    color: var(--dark-dark);
    text-align: left;
    justify-content: flex-start;
    padding: 0;
    font-weight: 400;
    padding: 8px 20px;
    width: 100%;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .modalMoreActionsDetailsBusiness .buttonMoreActionsDetailsBusiness:hover {
    padding: 0;
    padding: 8px 20px;
    background-color: var(--color-focus);
    color: var(--white-light);
    transition: none;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .modalEditMoneyDetailsBusiness {
    position: absolute;
    top: 80px;
    width: 300px;
    height: 140px;
    z-index: 800;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .titleHeaderInfoDetailsBusiness {
    color: var(--color-focus);
    font-size: 27px;
    font-weight: 400;
    display: inline;
    display: initial;
    cursor: pointer;
    margin: 0;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .titleHeaderInfoDetailsBusiness:hover {
    background-color: #fffcdc;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness {
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness .columnEditStampHeaderDetailsBusiness {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness .columnEditStampHeaderDetailsBusiness .nameStampHeaderDetailsBusiness { 
    font-size: 11px;
    padding: 1px 4px;
    border-radius: 2px;
    color: var(--white-light);
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 8px;
    text-align: center;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness .columnEditStampHeaderDetailsBusiness .nameStampHeaderDetailsBusiness.warning {
    color: black;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness .columnEditStampHeaderDetailsBusiness .modalEditStampDetailsBusiness { 
    position: absolute;
    top: 30px;
    width: 300px;
    min-height: 140px;
    z-index: 800;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness .columnEditStampHeaderDetailsBusiness .notificationStampDetailsBusiness{
    display: none;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness .columnEditStampHeaderDetailsBusiness:hover .notificationStampDetailsBusiness{
    background-color: black;
    color: white;
    padding: 4px;
    border-radius: 5px;
    position: absolute;
    top: -25px;
    min-width: 60px;
    z-index: 600;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    display: block;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness .columnEditStampHeaderDetailsBusiness .buttonStampHeaderDetailsBusiness {
    width: 25px;
    height: 25px;
    background-color: transparent;
    color: var(--dark-dark-three);
    font-size: 14px;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness .columnEditStampHeaderDetailsBusiness .buttonStampHeaderDetailsBusiness:hover {
    background-color: var(--white-dark);
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineBottomMoreInfoDetailsBusiness {
    margin-top: 12px;
    display: flex;
    align-items: center;
    vertical-align: middle;
    justify-content: flex-start;
    line-height: 30px;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineBottomMoreInfoDetailsBusiness .moneyInfoDetailsBusiness {
    line-height: 24px;
    font-weight: 400;
    font-size: 23px;
    color: var(--primary-color);
    cursor: pointer;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineBottomMoreInfoDetailsBusiness .moneyInfoDetailsBusiness:hover {
    background-color: #fffcdc;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineBottomMoreInfoDetailsBusiness .ownersInfoDetailsBusiness {
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineBottomMoreInfoDetailsBusiness .ownersInfoDetailsBusiness::before {
    content: " ";
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    width: 1px;
    min-height: 25px;
    margin-left: 16px;
    margin-right: 16px;
    background-color: var(--white-dark-two);
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineBottomMoreInfoDetailsBusiness .ownersInfoDetailsBusiness .iconUserMoreInfoDetailsBusiness {
    font-size: 18px;
    color: #404346;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineBottomMoreInfoDetailsBusiness .ownersInfoDetailsBusiness .nameUserMoreInfoDetailsBusiness {
    color: var(--primary-color);
    margin-left: 6px;
    font-size: 16px;
    font-weight: 400;
    
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .buttonStatusHeaderInfoDetailsBusiness {
    min-width: 32px;
    max-width: 100px;
    margin: 5px;
    height: 32px;
    font-size: 14px;
    font-weight: 600;
    background: var(--white-light);
    color: var(--white-light);
    border: 0;
    border: none;
    border-radius: 2px;
    margin-top: 0;
    padding: 0 4px;
    margin-bottom: 0px;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .buttonStatusHeaderInfoDetailsBusiness:hover {
    opacity: 0.8;
    transition: opacity 0.8s;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .buttonStatusHeaderInfoDetailsBusiness.positive {
    background-color: var(--color-success);
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .buttonStatusHeaderInfoDetailsBusiness.negative {
    background-color: var(--color-error);
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .buttonMoreActionsHeaderInfoDetailsBusiness {
    height: 32px;
    width: 32px;
    background: #fff;
    border: 0;
    border-radius: 2px;
    margin: 5px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    vertical-align: middle;
    color: var(--primary-color);
    border: 1px solid #cbcccd;

}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .buttonMoreActionsHeaderInfoDetailsBusiness:hover {
    background-color: var(--white-dark);
    transition: background-color .12s;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .dividerMoreActionsDetailsBusiness {
    width: 100%;
    height: 1px;
    background-color: var(--white-dark-two);
    margin-top: 10px;
    margin-bottom: 10px;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness {
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    flex-direction: row;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness .avatarOwnerHeaderDetailsBusiness {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid var(--white-dark-two);
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness .nameOwnerHeaderDetailsBusiness {
    padding: 0;
    margin: 0 5px 0 10px;
    line-height: 18px;
    text-align: right;
    display: flex;
    flex-direction: column;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness .notificationOwnerDetailsBusiness {
    background-color: black;
    color: white;
    padding: 4px;
    border-radius: 5px;
    position: absolute;
    top: -25px;
    min-width: 60px;
    z-index: 600;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    display: none;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness:hover .notificationOwnerDetailsBusiness {
    display: block;    
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness .nameOwnerHeaderDetailsBusiness .modalEditOwnerDetailsBusiness{
    position: absolute;
    top: 40px;
    left: 0;
    width: 300px;
    height: 140px;
    z-index: 800;
    text-align: left;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness .nameOwnerHeaderDetailsBusiness .anchorNameOwnerHeaderDetailsBusiness {
    padding: 0;
    margin: 0;
    font-weight: 600;
    display: flex;
    justify-content: flex-end;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness .nameOwnerHeaderDetailsBusiness .anchorNameOwnerHeaderDetailsBusiness:hover {
    text-decoration: underline!important;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness .nameOwnerHeaderDetailsBusiness .levelNameOwnerHeaderDetailsBusiness {
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    color: var(--dark-light);
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness .buttonEditOwnerHeaderDetailsBusiness {
    width: 25px;
    height: 25px;
    background-color: transparent;
    color: var(--dark-dark-three);
    font-size: 14px;
    margin-right: 10px;
}

.detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .editOwnersHeaderInfoDetailsBusiness .buttonEditOwnerHeaderDetailsBusiness:hover {
    background-color: var(--white-dark);
}

/* ends styles header */
/* ends styles header */
/* ends styles header */


/* start styles body */
/* start styles body */
/* start styles body */
.detailsBusiness .bodyDetailsBusiness {
    height: 100%;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness {
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .headerBoxOthersBodyDetailsBusiness {
    border-bottom: 1px solid #e5e5e5;
    display: inline-flex;
    justify-content: space-between;
    vertical-align: middle;
    align-items: center;
    padding-bottom: 16px;
    width: 100%;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .headerBoxOthersBodyDetailsBusiness .buttonEditPersonOthersDetailsBusiness {
    width: 25px;
    height: 25px;
    background-color: transparent;
    color: var(--dark-dark-three);
    font-size: 14px;
    color: var(--primary-color);
    border: 1px solid var(--white-dark-two);
    padding: 4px;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .headerBoxOthersBodyDetailsBusiness .buttonEditPersonOthersDetailsBusiness:hover {
    background-color: var(--white-dark);
}


.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .headerBoxOthersBodyDetailsBusiness .subtitleOthersDetailsBusiness {
    font-size: 13px;
    color: #721ea9;
    font-weight: 600;
    text-transform: uppercase;
    width: 100%;
    display: block;
    
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness {
    margin: 8px 0;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .colOne {
    padding-right: 10px;
    display: flex;
    justify-content: flex-end;
    display: flex;
    align-items: center;
    vertical-align: middle;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .colOne .avatarClientOthersDetailsBusiness {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: var(--color-focus);
    fill: var(--color-focus);
    border: 2px solid rgba(38,41,44,.64);
    box-sizing: border-box;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .colOne span {
    font: 400 13px/16px Source Sans Pro,sans-serif;
    font-weight: 600;
    color: rgba(38,41,44,.64);
    text-align: right;
    word-wrap: break-word;
    margin: 2px 0;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .colTwo {
    padding-left: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    vertical-align: middle;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .buttonBodyOthersDetailsBusiness {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .buttonBodyOthersDetailsBusiness:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .buttonBodyOthersDetailsBusiness.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
    margin-left: 10px;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .buttonBodyOthersDetailsBusiness.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .colTwo span.nameClientBodyOthersDetailsBusiness{
    color: black;
    font-size: 18px;
    font-weight: 600;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .colTwo span {
    color: var(--primary-color);
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness span.minumus {
    color: #747678;
    font-size: 13px;
    line-height: 9.2px;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness.general {
    margin-top: 15px;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .progressOthersDetailsBusiness {
    width: 100%;
    display: block;
    content:  " ";
    background-color: var(--color-success);
    border-radius: 120px;
    height: 16px;
    margin-bottom: 10px;
}

.detailsBusiness .bodyDetailsBusiness .boxOthersBodyDetailsBusiness .bodyBoxOthersBodyDetailsBusiness .lineBodyOthersDetailsBusiness .progressOthersDetailsBusiness:hover {
    -webkit-transform: scaleX(1.2);
            transform: scaleX(1.2);
    -webkit-transform: scaleY(1.3);
            transform: scaleY(1.3);
}

.detailsBusiness .bodyDetailsBusiness .boxActivitiesBodyDetailsBusiness {
    padding: 0;
}

.detailsBusiness .bodyDetailsBusiness .menusActivitiesDetailsBusiness {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    overflow: hidden;
}

.detailsBusiness .bodyDetailsBusiness .boxActivitiesHistoricBodyDetailsBusiness {
    background-color: transparent;
    box-shadow: none;
    border: none;
    padding: 20px 0;
    text-align: center;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    vertical-align: middle;
    margin-bottom: 200px;
    min-height: 1000px;
}

.detailsBusiness .bodyDetailsBusiness .boxActivitiesHistoricBodyDetailsBusiness .statusActivitiesDetailsBusiness {
    background-color: #747678;
    border-radius: 180px;
    color: white;
    padding: 1px 20px;
    margin: 10px;
    font-weight: 400;
    text-transform: uppercase;
    font-size: 14px;
}

/* ends styles body */
/* ends styles body */
/* ends styles body */


@media (min-width: 767px) and (max-width: 1024px)
{
    .detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineTopMoreInfoDetailsBusiness .columnEditStampHeaderDetailsBusiness .modalEditStampDetailsBusiness {
        position: fixed;
        top: 30px;
        left: 60px;
    }
}

@media (max-width: 425px)
{
    .detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineBottomMoreInfoDetailsBusiness {
        flex-direction: column;        
    }

    .detailsBusiness .headerDetailsBusiness .lineHeaderInfoDetailsBusiness .columnHeaderInfoDetailsBusiness .lineBottomMoreInfoDetailsBusiness .ownersInfoDetailsBusiness::before {
        display: none;
    }
}
.modalEditStamp {
    padding: 0;
    margin: 0;
    background-color: var(--white-light);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: var(--white-light);
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    position: relative;
    /* overflow: hidden; */
}

.modalEditStamp .headerModalEditStamp {
    padding: 15px;
    border-bottom: 1px solid var(--white-dark-two);
}

.modalEditStamp .headerModalEditStamp .titleModalEditStamp {
    color: var(--dark-light);
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.modalEditStamp .bodyModalEditStamp {
    padding: 5px 0px;
}

.modalEditStamp .bodyModalEditStamp.active {
    padding: 15px;
}

.modalEditStamp .bodyModalEditStamp .lineChooseColorModalEditStamp {
    display: inline-flex;
    justify-content: center;
    vertical-align: middle;
    width: 100%;
    padding: 5px;
}

.modalEditStamp .bodyModalEditStamp .lineChooseColorModalEditStamp .buttonChooseColorModalEditStamp {
    border-radius: 4px;
    width: 25px;
    height: 25px;
    margin: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    cursor: pointer;
}

.modalEditStamp .bodyModalEditStamp .lineChooseColorModalEditStamp .buttonChooseColorModalEditStamp:hover {
    opacity: 0.8;
}

.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp {
    padding: 0;
    margin: 0;
    margin-top: 8px;
    padding: 8px 20px;
    margin-bottom: 8px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    position: relative;
    cursor: pointer;
}

.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .buttonEditStampModalEditStamp {
    color: var(--white-light);
    font-size: 12px;
    cursor: pointer;
}

.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .iconCheckedStampModalEditStamp {
    color: var(--color-focus);
    font-size: 12px;
}

.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp:hover {
    background-color: var(--color-focus);
}

.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .stampModelEditStamp {
    font-size: 11px;
    padding: 1px 4px;
    border-radius: 2px;
    color: var(--dark-dark-three);
    font-weight: 600;
    text-transform: uppercase;
}

.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .stampModelEditStamp.none {
    border: 1px solid var(--dark-dark-three);
}

.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp:hover .stampModelEditStamp.none {
    border-color: var(--white-light);
    color: var(--white-light);
}   

.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .stampModelEditStamp.warning {
    color: black;
}

.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .stampModelEditStamp.success,
.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .stampModelEditStamp.purple,
.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .stampModelEditStamp.focus,
.modalEditStamp .bodyModalEditStamp .buttonChooseStampModalEditStamp .stampModelEditStamp.error {
    color: var(--white-light);
}

.modalEditStamp .bodyModalEditStamp .lineChooseColorModalEditStamp .buttonChooseColorModalEditStamp .iconCheckedColorModalEditStamp {
    font-size: 10px;
    color: black;
}

.modalEditStamp .footerModalEditStamp { 
    border-top: 1px solid var(--white-dark-two);
    background-color: var(--white-light);
    height: 100%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
}

.modalEditStamp .footerModalEditStamp.active { 
    padding: 8px 15px;
}

.modalEditStamp .footerModalEditStamp .buttonModalEditStamp {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 80px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.modalEditStamp .footerModalEditStamp .buttonPlusModalEditStamp {
    color: var(--color-focus);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    width: 100%;
    font-weight: 400;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: transparent;
    border-radius: 2px;
    border: none;
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: left;
}

.modalEditStamp .footerModalEditStamp .buttonPlusModalEditStamp:hover {
    background-color: var(--color-focus);
    color: var(--white-light);
}

.modalEditStamp .footerModalEditStamp .buttonModalEditStamp:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.modalEditStamp .footerModalEditStamp .buttonModalEditStamp.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.modalEditStamp .footerModalEditStamp .buttonModalEditStamp.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}
.box {
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 16%), 0 2px 2px 0 rgb(0 0 0 / 5%), 0 3px 1px -2px rgb(0 0 0 / 6%), 0 1px 5px 0 rgb(0 0 0 / 10%);
    background-color: var(--white-light);
    border-radius: 4px;
    box-sizing: border-box;
    display: block;
    margin-bottom: 24px;
    transition: box-shadow .1s linear;
    background: #fff;
    font: 400 15px/20px Source Sans Pro,sans-serif;
    color: var(--primary-color);
    padding: 20px;
    z-index: 800;
}
.itemHistoricActivity {
    background-color: transparent;
    width: 100%;
    height: 85px;
}

/* start all style in progress */
/* start all style in progress */
/* start all style in progress */
.itemHistoricActivity .lineProgressItemHistoricActivity {
    height: 100%;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnTypeItemHistoricActivity {
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    vertical-align: middle;
    padding-top: 5px;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnTypeItemHistoricActivity .iconProgressTypeItemHistoricActivity {
    color: var(--dark-light);
    width: 36px;
    height: 36px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    position: absolute;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnTypeItemHistoricActivity .lineProgressTypeItemHistoricActivity {
    width: 2px;
    height: 100%;
    padding-top: 5px;
    content: " ";
    display: block;
    border: none;
    border: 2px solid transparent;
    border-left: 2px solid var(--white-dark-two);
    border-style: dashed;
    margin-left: 16px;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity {
    background-color: var(--white-light);
    display: flex;
    flex-direction: column;
    vertical-align: middle;
    align-items: flex-start;
    justify-content: center;
    padding-left: 15px;
    border-radius: 5px;
    border: 1px solid var(--white-dark-two);
    position: relative;
    /* border: 2px solid green; */
    margin-top: 5px;
    margin-bottom: 5px;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .buttonMoreActionsItemHistoricActivity {
    position: absolute;
    right: 15px;
    top: 0;
    
    width: 25px;
    height: 25px;
    background-color: transparent;
    color: var(--dark-dark-three);
    font-size: 14px;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .buttonMoreActionsItemHistoricActivity:hover {
    background-color: var(--white-dark);
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .modalMoreActionsEditActivity {
    position: absolute;
    top: 40px;
    right: 5px;
    z-index: 800;
    width: 250px;
    min-height: 140px;
    height: 100%;
    padding: 0;
    margin: 0;
    background-color: var(--white-light);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    padding-top: 10px;
    padding-bottom: 10px;
    border: 0.1px solid var(--white-dark-two);
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .modalMoreActionsEditActivity::after {
    width: 18px;
    height: 18px;
    background-color: var(--white-light);
    display: block;
    content: " ";
    position: absolute;
    top: -9px;
    right: 10px;
    z-index: 700;
    -webkit-transform: rotate(135deg);
            transform: rotate(135deg);
    border: none;
    border-left: 1px solid var(--white-dark-two);
    border-bottom: 1px solid var(--white-dark-two);
    /* box-shadow: 0px 0px 7px 1px var(--white-dark-two); */
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .modalMoreActionsEditActivity .buttonMoreActionsEditActivity {
    border: none;
    font-size: 15px;
    background-color: transparent;
    color: var(--dark-dark);
    text-align: left;
    justify-content: flex-start;
    padding: 0;
    font-weight: 400;
    padding: 8px 20px;
    width: 100%;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .modalMoreActionsEditActivity .buttonMoreActionsEditActivity:hover {
    padding: 0;
    padding: 8px 20px;
    background-color: var(--color-focus);
    color: var(--white-light);
    transition: none;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity::before {
    display: block;
    width: 16px;
    height: 16px;
    content: " ";
    background-color: var(--white-light);
    position: absolute;
    top: 10px;
    left: -8px;
    border: 1px solid var(--white-dark-two);
    border-right: none;
    border-top: none;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .checkFinishedItemHistoricActivity {
    width: auto;
    width: initial;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .ckeckFinishedItemHistoricActivity {
    position: relative;
    
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .ckeckFinishedItemHistoricActivity:hover .helpCheckFinishedItemHistoricActivity {
    display: block;

}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .ckeckFinishedItemHistoricActivity .helpCheckFinishedItemHistoricActivity {
    position: absolute;
    top: -40px;
    left: 0;
    background-color: black;
    color: white;
    padding: 4px;
    border-radius: 5px;
    position: absolute;
    min-width: 140px;
    z-index: 600;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
    display: none;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .checkFinishedItemHistoricActivity .squareCheck {
    border-radius: 50%;
    
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .checkFinishedItemHistoricActivity .squareCheck:hover {
    border-color: var(--color-success);
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .dateStartTaskItemHistoricActivity {
    color: var(--dark-dark-three);
    margin-right: 10px;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .ownerTaskItemHistoricActivity {
    margin-right: 10px;
    color: var(--dark-dark-three);
    
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .nameTaskItemHistoricActivity {
    color: var(--primary-color);
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    font-weight: 300;
}

.itemHistoricActivity .lineProgressItemHistoricActivity .columnDetailsItemHistoricActivity .nameTaskItemHistoricActivity .iconNameTaskItemHistoricActivity {
    font-size: 10px;
    margin-right: 5px;
}

/* ends all style in progress */
/* ends all style in progress */
/* ends all style in progress */


/* start all styles finished */
/* start all styles finished */
/* start all styles finished */

.itemHistoricActivity .lineFinishedItemHistoricActivity {
    height: 100%;
}

.itemHistoricActivity .lineFinishedItemHistoricActivity .columnTypeItemHistoricActivity {
    overflow: hidden;
    display: flex;
    justify-content: flex-start;
    vertical-align: middle;
}

.itemHistoricActivity .lineFinishedItemHistoricActivity .columnTypeItemHistoricActivity .iconFinishedTypeItemHistoricActivity {
    color: var(--dark-light);
    width: 24px;
    height: 24px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    position: absolute;
    margin-left: 5px;
}

.itemHistoricActivity .lineFinishedItemHistoricActivity .columnTypeItemHistoricActivity .lineFinishedTypeItemHistoricActivity {
    width: 2px;
    height: 100%;
    content: " ";
    display: block;
    border: none;
    border: 2px solid transparent;
    border-left: 2px solid var(--white-dark-two);
    margin-left: 16px;
}

.itemHistoricActivity .lineFinishedItemHistoricActivity .columnTypeItemHistoricActivity .lineFinishedTypeItemHistoricActivity.disabled {
    border: none;
}

.itemHistoricActivity .lineFinishedItemHistoricActivity .columnDetailsItemHistoricActivity {
    background-color: transparent;
    flex-direction: column;
    border-radius: 5px;
    position: relative;
}

.itemHistoricActivity .lineFinishedItemHistoricActivity .columnDetailsItemHistoricActivity .dateStartTaskItemHistoricActivity {
    color: var(--dark-dark-three);
    margin-right: 10px;
}

.itemHistoricActivity .lineFinishedItemHistoricActivity .columnDetailsItemHistoricActivity .ownerTaskItemHistoricActivity {
    margin-right: 10px;
    color: var(--dark-dark-three);
    
}

.itemHistoricActivity .lineFinishedItemHistoricActivity .columnDetailsItemHistoricActivity .nameTaskItemHistoricActivity {
    color: var(--primary-color);
    font-weight: 400;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    font-weight: 300;
}

.itemHistoricActivity .lineFinishedItemHistoricActivity .columnDetailsItemHistoricActivity .nameTaskItemHistoricActivity .iconNameTaskItemHistoricActivity {
    font-size: 10px;
    margin-right: 5px;
}

/* ends all styles finished */
/* ends all styles finished */
/* ends all styles finished */
.messageConfirmation {
    background-color: rgba(0, 0, 0, 0.342);
}

.modal-content { /* bootstrap class */
    background-color: transparent;
    border: none;
}

.messageConfirmation .content {
    border-radius: 20px;
    background-color: var(--white-light);
    overflow: hidden;
}

/* Start styling header */
/* Start styling header */
/* Start styling header */
.messageConfirmation .content .header {
    border-bottom: 2px solid var(--dark-dark);
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    padding: 30px;
}

.messageConfirmation .content .header .status{
    font-weight: 600;
    font-size: 16px;
}

.messageConfirmation .content .header .status.success{ color: #28a745 }

.messageConfirmation .content .header .status.error{ color: #dc3545 }

.messageConfirmation .content .header .status.warning{ color: #ffc107 }

.messageConfirmation .content .header .status.information{ color: #007bff }

.messageConfirmation .content .header .status .iconStatus{
    margin-right: 10px;
    font-size: 22x;
}

.messageConfirmation .content .header .close{
    border: 1px solid var(--dark-dark);
    padding: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    background-color: transparent!important;
}
.messageConfirmation .content .header .button .iconButton{
    color: var(--dark-dark);
}
/* Ends styling header */
/* Ends styling header */
/* Ends styling header */

/* Start styling body */
/* Start styling body */
/* Start styling body */
.messageConfirmation .content .body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    text-align: justify;
    padding: 30px;
    flex-direction: column;
}

.messageConfirmation .content .body p {
    font-size: 18px;
    font-weight: 400;
    color: var(--dark-dark);
}

.messageConfirmation .content .body .lineBodyMessageConfirmation {
    width: 100%;
}

.messageConfirmation .content .body .lineBodyMessageConfirmation .columnBodyMessageConfirmation .buttonMessageConfirmation {
    width: 100px;
    font-weight: 400;
    height: 40px;
    font-size: 14px;
    margin: 0;
    background-color: var(--color-error);
}

.messageConfirmation .content .body .lineBodyMessageConfirmation .columnBodyMessageConfirmation .buttonMessageConfirmation.positive {
    background-color: var(--color-success);
}
/* Ends styling body */
/* Ends styling body */
/* Ends styling body */
.activities {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.activities .containerNavigationHeaderActivities {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities { 
    display: flex;
    margin-bottom: 20px;
    position: relative;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .dropIntervalsHeaderActivities {
    position: absolute;
    width: 550px;
    height: 140px;
    top: 35px;
    right: 10px;
    background-color: var(--white-light);
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    z-index: 900;
    border-radius: 5px;
    padding: 8px 0;
    color: var(--dark-dark-three);
    font-size: 14px;    
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .lineBodyDropIntervalsHeaderActivities {
   padding: 13px;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .lineFooterDropIntervalsHeaderActivities {
    position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
    background-color: #f7f7f7;
    border-top: 1px solid #e5e5e5;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .lineFooterDropIntervalsHeaderActivities .buttonIntervalsHeaderActivities {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .lineFooterDropIntervalsHeaderActivities .buttonIntervalsHeaderActivities:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .lineFooterDropIntervalsHeaderActivities .buttonIntervalsHeaderActivities.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .lineFooterDropIntervalsHeaderActivities .buttonIntervalsHeaderActivities.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}












.activities .containerTypesAndIntervalsNavigationHeaderActivities .menusTypesAndIntervalsActivities {
    display: inline-flex;
    flex-direction: row;
    border: none;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .menusTypesAndIntervalsActivities button {
    font-size: 14px;
    height: 30px;
    border-radius: 2px;
    margin-right: 5px;
    margin-left: 5px;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .menusTypesAndIntervalsActivities button.active {
    border: 1px solid var(--color-focus);
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .menusTypesAndIntervalsActivities.types {
    justify-content: flex-start;
}

.activities .containerTypesAndIntervalsNavigationHeaderActivities .menusTypesAndIntervalsActivities.intervals {
    justify-content: flex-end;
}

.activities .containerNavigationHeaderActivities .buttonNavigationHeaderActivities {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.activities .containerNavigationHeaderActivities .buttonNavigationHeaderActivities:hover {
    background-color: var(--white-dark);
}

.activities .containerNavigationHeaderActivities .buttonNavigationHeaderActivities:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.activities .containerNavigationHeaderActivities .buttonNavigationHeaderActivities:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.activities .containerNavigationHeaderActivities .buttonNavigationHeaderActivities.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.activities .containerNavigationHeaderActivities .buttonPlusNavigationHeaderActivities {
    width: 100px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.activities .containerNavigationHeaderActivities .buttonTrashNavigationHeaderActivities {
    font-size: 10px;
    width: 32px;
    height: 32px;
    color: var(--primary-color);
    background-color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
    border: 1px solid var(--white-dark-two);
}

.activities .containerNavigationHeaderActivities .filterHeaderActivities {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.activities .containerNavigationHeaderActivities .filterHeaderActivities .iconFilterHeaderActivities {
    color: red;
    font-size: 14px;
    color: var(--dark-dark);
}

.activities .containerNavigationHeaderActivities .filterHeaderActivities .selectFilterHeaderActivities {
    width: 100%;
    border: none;
    cursor: pointer;
}

.activities .containerNavigationHeaderActivities .filterHeaderActivities .selectFilterHeaderActivities .optionsSelectMulti {
    min-width: 200px;
}

.activities .containerNavigationHeaderActivities .filterHeaderActivities .selectFilterHeaderActivities .textShowSelectMulti {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.activities .containerNavigationHeaderActivities .filterHeaderActivities .selectFilterHeaderActivities .fieldSelectMulti {
    border: none;
}

.activities .containerNavigationHeaderActivities .filterOwnersHeaderActivities {
    margin-right: 20px;
    min-width: 180px;    
    position: relative;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
    cursor: pointer;
}

.activities .containerNavigationHeaderActivities .filterOwnersHeaderActivities .selectOwnersHeaderActivities .fieldSelectMulti {
    border: none;
}
.tableEditActivities {
    background-color: var(--white-light);
}

.tableEditActivities .helpTableEditActivities {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;

}

.tableEditActivities table, tr, th, td {
    margin: 0;
}

.tableEditActivities table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditActivities table .buttonCheckTableEditActivities {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.tableEditActivities table .buttonCheckTableEditActivities .squareCheck {
    border: 2px solid var(--white-dark-two);
    margin: 0;
    border-radius: 2px;
}

.tableEditActivities table .buttonCheckTableEditActivities.active .squareCheck {
    background-color: var(--color-focus);
    border: 2px solid var(--color-focus);
}

.tableEditActivities table .buttonCheckTableEditActivities.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.tableEditActivities table .buttonFinishTableEditActivities {
    width: initial;
    padding-left: 10px;
    border: 1px solid #eee;
    border-right: none;
    border-bottom: none;
    border-left: none;
    height: 100%;
    width: 100%;
}

.tableEditActivities table .buttonFinishTableEditActivities {
    position: relative;
    
}

.tableEditActivities table .buttonFinishTableEditActivities .squareCheck {
    border-radius: 50%;
    
}

.tableEditActivities table .buttonFinishTableEditActivities .squareCheck:hover {
    border-color: var(--color-success);
}

.tableEditActivities table .buttonFinishTableEditActivities.active .squareCheck {
    border: 2px solid var(--color-success);
    background-color: var(--color-success);
}

.tableEditActivities table .buttonFinishTableEditActivities.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.tableEditActivities table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
  
}

.tableEditActivities table tr {
    height: 32px;
}

.tableEditActivities .theadTableEditActivities td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
}
.tableEditActivities .theadTableEditActivities td.minimus {
    width: 90px!important;
}

.tableEditActivities .theadTableEditActivities td:last-of-type {
    border-right: none;
}

.tableEditActivities td.checkTableEditActivities {
    text-align: center;
    vertical-align: middle;
    width: 35px!important;
    padding: 0;
}

.tableEditActivities td.finishTableEditActivities {
    text-align: center;
    vertical-align: middle;
    padding: 0;
    height: 25px!important;
}

.tableEditActivities .tbodyTableEditActivities:hover {
    background-color: var(--white-dark);
}

.tableEditActivities .tbodyTableEditActivities td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
}

.tableEditActivities .table .tbodyTableEditActivities td.checkTableEditActivities { 
    border: 1px solid #eee;
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
}

.tableEditActivities .table .tbodyTableEditActivities td.finishTableEditActivities {
    border: 1px solid #eee;
    position: relative;
    align-items: center;
}

.tableEditActivities .tbodyTableEditActivities td:hover .buttonEditTableEditActivities {
    opacity: 1;
}

.tableEditActivities .tbodyTableEditActivities td:hover .editTableEditActivities {    
    border: 1px solid var(--color-focus)!important;
}

.tableEditActivities .tbodyTableEditActivities td .editTableEditActivities.noEdit .buttonFieldEdit {
    display: none;
}

.tableEditActivities .tbodyTableEditActivities td .stampEditActivities {
    position: absolute;
    z-index: 900;
    height: 20px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    font-size: 11px;
    padding: 1px 4px;
    border-radius: 2px;
    color: var(--white-light);
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 8px;
    text-align: center;
    color: var(--white-light);

}

.tableEditActivities .tbodyTableEditActivities td .editTableEditActivities .modalFieldEdit {
    z-index: 1000;
}

.tableEditActivities .tbodyTableEditActivities .detailsTableEditActivities {
    position: relative;
    border: 1px solid #eee;
    display: inline-flex;
    width: 100%;
    height: 32px;
}

.tableEditActivities .tbodyTableEditActivities .detailsTableEditActivities button.button.buttonShowDetailsTableEditActivities { 
    background-color: transparent;
    width: 20px;
    font-size: 14px;
    color: var(--color-focus);
    margin-left: auto;
    margin-right: auto;
}

.tableEditActivities .tbodyTableEditActivities .detailsTableEditActivities .messageDetailsTableEditActivities {
    position: absolute;
    top: 0;
    left: -20px;
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
}
.owners {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.owners .containerNavigationHeaderOwners {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.owners .containerNavigationHeaderOwners .buttonNavigationHeaderOwners {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.owners .containerNavigationHeaderOwners .buttonNavigationHeaderOwners:hover {
    background-color: var(--white-dark);
}

.owners .containerNavigationHeaderOwners .buttonNavigationHeaderOwners:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.owners .containerNavigationHeaderOwners .buttonNavigationHeaderOwners:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.owners .containerNavigationHeaderOwners .buttonNavigationHeaderOwners.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.owners .containerNavigationHeaderOwners .buttonPlusNavigationHeaderOwners {
    width: 120px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.owners .containerNavigationHeaderOwners .buttonTrashNavigationHeaderOwners {
    font-size: 10px;
    width: 32px;
    height: 32px;
    color: var(--primary-color);
    background-color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
    border: 1px solid var(--white-dark-two);
}

.owners .containerNavigationHeaderOwners .filterFontsHeaderOwners {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.owners .containerNavigationHeaderOwners .filterFontsHeaderOwners .iconFilterFontsHeaderOwners {
    color: red;
    font-size: 14px;
    color: var(--dark-dark);
}

.owners .containerNavigationHeaderOwners .filterFontsHeaderOwners .selectFontHeaderOwners {
    cursor: pointer;
    width: 100%;
    border: none;
}

.owners .containerNavigationHeaderOwners .filterFontsHeaderOwners .selectFontHeaderOwners .textShowSelectMulti {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.owners .containerNavigationHeaderOwners .filterFontsHeaderOwners .selectFontHeaderOwners .fieldSelectMulti {
    border: none;
}
.tableEditOwners {
    background-color: var(--white-light);
}

.tableEditOwners .helpTableEditOwners {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;

}

.tableEditOwners table, tr, th, td {
    margin: 0;
}

.tableEditOwners table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditOwners table .buttonCheckTableEditOwners {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.tableEditOwners table .buttonCheckTableEditOwners .squareCheck {
    border: 2px solid var(--white-dark-two);
    margin: 0;
    border-radius: 2px;
}

.tableEditOwners table .buttonCheckTableEditOwners.active .squareCheck {
    background-color: var(--color-focus);
    border: 2px solid var(--color-focus);
}

.tableEditOwners table .buttonCheckTableEditOwners.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.tableEditOwners table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
  
}

.tableEditOwners table tr {
    height: 32px;
}

.tableEditOwners .theadTableEditOwners td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
}

.tableEditOwners .theadTableEditOwners td:last-of-type {
    border-right: none;
}

.tableEditOwners td.checkTableEditOwners {
    text-align: center;
    vertical-align: middle;
    width: 35px!important;
    padding: 0;
}

.tableEditOwners .tbodyTableEditOwners:hover {
    background-color: var(--white-dark);
}

.tableEditOwners .tbodyTableEditOwners td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
}

.tableEditOwners .table .tbodyTableEditOwners td.checkTableEditOwners {
    border: 1px solid #eee;
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
}

.tableEditOwners .tbodyTableEditOwners td:hover .buttonEditTableEditOwners {
    opacity: 1;
}

.tableEditOwners .tbodyTableEditOwners td:hover .editTableEditOwners {    
    border: 1px solid var(--color-focus)!important;
}

.tableEditOwners .tbodyTableEditOwners td .editTableEditOwners.noEdit .buttonFieldEdit {
    display: none;
}

.tableEditOwners .tbodyTableEditOwners td .stampEditOwners {
    position: absolute;
    z-index: 900;
    height: 20px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    font-size: 11px;
    padding: 1px 4px;
    border-radius: 2px;
    color: var(--white-light);
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 8px;
    text-align: center;
    color: var(--white-light);

}

.tableEditOwners .tbodyTableEditOwners td .editTableEditOwners .modalFieldEdit {
    z-index: 1000;
}

.tableEditOwners .tbodyTableEditOwners td .buttonShowConfigTableEditOwners {
    background-color: transparent;
    width: 100%;
    font-size: 14px;
    color: var(--color-focus);
    margin-left: auto;
    margin-right: auto;
    position: relative;
    border: 1px solid #eee;
    width: 100%;
    height: 32px;
}

.tableEditOwners .tbodyTableEditOwners td .companyEditOwners {
    position: absolute;
    z-index: 99;
    height: 20px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1px 4px;
    border-radius: 2px;
    margin-right: 8px;
    text-align: center;
}
.detailsPermissions {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.detailsPermissions .warningDetailsPermissions {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.detailsPermissions .scrollDetailsPermissions {
    overflow: scroll;
    height: 100%;
}

.detailsPermissions .headerDetailsPermissions {
    margin-top: 20px;
}

.detailsPermissions .headerDetailsPermissions h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.detailsPermissions .headerDetailsPermissions h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-success);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);

}

.detailsPermissions .bodyDetailsPermissions  {
    margin-bottom: 250px;
}

.detailsPermissions .bodyDetailsPermissions .lineCollectionsDetailsPermissions .collectionsPermissionsGroupDetailsPermissions .labelSelectMulti {
    text-align: center;
    width: 100%;
}

.detailsPermissions .bodyDetailsPermissions .lineCollectionsDetailsPermissions .collectionsPermissionsGroupDetailsPermissions .fieldSelectMulti {
    border-radius: 120px;
    height: 38px;
}

.detailsPermissions .bodyDetailsPermissions .lineTitleHeaderChecksConfigDetailsPermissions {
    background-color: var(--dark-dark-three);
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 48px;
}

.detailsPermissions .bodyDetailsPermissions .lineTitleHeaderChecksConfigDetailsPermissions .columnTitleHeaderChecksConfigDetailsPermissions {
    color: var(--white-light);
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.detailsPermissions .bodyDetailsPermissions .lineHeaderChecksConfigDetailsPermissions {
    background-color: var(--white-dark-two);
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
}

.detailsPermissions .bodyDetailsPermissions .lineHeaderChecksConfigDetailsPermissions .columnHeaderChecksConfigDetailsPermissions {
    padding: 0;
    padding-left: 20px;   
    padding-right: 20px;
    font-weight: 600;
    text-transform: uppercase;
}

.detailsPermissions .bodyDetailsPermissions .lineHeaderChecksConfigDetailsPermissions .columnHeaderChecksConfigDetailsPermissions .checkHeaderConfigDetailsPermissions {
    width: auto!important;
    width: initial!important;
    margin-left: auto;
    margin-right: auto;
}

.detailsPermissions .bodyDetailsPermissions .lineHeaderChecksConfigDetailsPermissions .columnHeaderChecksConfigDetailsPermissions .checkHeaderConfigDetailsPermissions .squareCheck {
    margin: 0;
}

.detailsPermissions .bodyDetailsPermissions .lineHeaderChecksConfigDetailsPermissions .columnHeaderChecksConfigDetailsPermissions .checkHeaderConfigDetailsPermissions.active .squareCheck {
    border-color: var(--color-success);
    background-color: var(--color-success);
}

.detailsPermissions .bodyDetailsPermissions .lineHeaderChecksConfigDetailsPermissions .columnHeaderChecksConfigDetailsPermissions .checkHeaderConfigDetailsPermissions.active .iconCheck {
    color: var(--white-light);
}

.detailsPermissions .bodyDetailsPermissions .lineBodyChecksConfigDetailsPermissions {
    padding-top: 10px;
    padding-bottom: 10px;
    border: 1px solid var(--white-dark-two);
    border-top: none;
}

.detailsPermissions .bodyDetailsPermissions .lineBodyChecksConfigDetailsPermissions .columnBodyChecksConfigDetailsPermissions {
    padding: 0;
    padding-left: 20px;   
    padding-right: 20px;
    font-weight: 300;
}

.detailsPermissions .bodyDetailsPermissions .lineBodyChecksConfigDetailsPermissions .columnBodyChecksConfigDetailsPermissions .checkBodyConfigDetailsPermissions {
    width: auto;
    width: initial;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
}

.detailsPermissions .bodyDetailsPermissions .lineBodyChecksConfigDetailsPermissions .columnBodyChecksConfigDetailsPermissions .checkBodyConfigDetailsPermissions .squareCheck {
    margin: 0;
}

.detailsPermissions .bodyDetailsPermissions .lineBodyChecksConfigDetailsPermissions .columnBodyChecksConfigDetailsPermissions .checkBodyConfigDetailsPermissions.active .squareCheck {
    border-color: var(--color-success);
    background-color: var(--color-success);
}

.detailsPermissions .bodyDetailsPermissions .lineBodyChecksConfigDetailsPermissions .columnBodyChecksConfigDetailsPermissions .checkBodyConfigDetailsPermissions.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.detailsPermissions .bodyDetailsPermissions .lineFooterChecksConfigDetailsPermissions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
    padding-left: 13px ;
    padding-right: 13px ;
    background-color: var(--dark-dark-three);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.detailsPermissions .bodyDetailsPermissions .lineFooterChecksConfigDetailsPermissions .buttonCheckConfigDetailsPermissions {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
}

.detailsPermissions .bodyDetailsPermissions .lineFooterChecksConfigDetailsPermissions .buttonCheckConfigDetailsPermissions:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.detailsPermissions .bodyDetailsPermissions .lineFooterChecksConfigDetailsPermissions .buttonCheckConfigDetailsPermissions.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.detailsPermissions .bodyDetailsPermissions .lineFooterChecksConfigDetailsPermissions .buttonCheckConfigDetailsPermissions.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}
.permissions {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.permissions .warningPermissions {
    background-color: var(--color-warning);
    margin: 5px;
    padding: 10px;
    font-weight: 600;
    border-radius: 5px;
}

.permissions .scrollPermissions {
    overflow: scroll;
    height: 100%;
}

.permissions .headerPermissions {
    margin-top: 20px;
}

.permissions .headerPermissions h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.permissions .headerPermissions h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-success);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);

}

.permissions .bodyPermissions  {
    margin-bottom: 250px;
}

.permissions .bodyPermissions .lineChosenPermissionGroupPermissions {
    padding-bottom: 20px;
}

.permissions .bodyPermissions .lineChosenPermissionGroupPermissions .columnChosenPermissionsGroupPermissions { 
    width: 100%;
}

.permissions .bodyPermissions .lineChosenPermissionGroupPermissions .columnChosenPermissionsGroupPermissions .buttonPlusPermissionsGroupPermisions {
    width: 100px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
}

.permissions .bodyPermissions .lineTitleHeaderConfigPermissions {
    background-color: var(--dark-dark-three);
    padding-top: 10px;
    padding-bottom: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 48px;
}

.permissions .bodyPermissions .lineTitleHeaderConfigPermissions .columnTitleHeaderConfigPermissions {
    color: var(--white-light);
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.permissions .bodyPermissions .lineBodyConfigPermissions.final {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.permissions .bodyPermissions .lineBodyConfigPermissions {
    background-color: var(--white-light);
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    align-items: center;
    border: 1px solid var(--white-dark-two);
    border-top: none;
}


.permissions .bodyPermissions .lineBodyConfigPermissions .columnBodyConfigPermissions {
    padding: 0;
    padding-left: 20px;   
    padding-right: 20px;
    font-weight: 300;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
}

.permissions .bodyPermissions .lineBodyConfigPermissions .columnBodyConfigPermissions .buttonBodyConfiPermissions {
    background-color: transparent;
    color: var(--dark-dark-three);
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 50%;
}

.permissions .bodyPermissions .lineBodyConfigPermissions .columnBodyConfigPermissions .buttonBodyConfiPermissions:hover {
    background-color: var(--dark-dark-three);
    color: var(--white-dark-two);
    transition: background-color 1s;
}
.personalPreferences {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.personalPreferences .scrollPersonalPreferences {
    overflow: scroll;
    height: 100%;
    margin-bottom: 300px;
}

.personalPreferences .headerPersonalPreferences {
    margin-top: 20px;
}

.personalPreferences .headerPersonalPreferences h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.personalPreferences .headerPersonalPreferences h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-focus);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.personalPreferences .bodyPersonalPreferences {
    margin-bottom: 300px;
}

.personalPreferences .bodyPersonalPreferences .lineTitleHeaderConfigPersonalPreferences {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
}

.personalPreferences .bodyPersonalPreferences .lineTitleHeaderConfigPersonalPreferences .columnTitleHeaderConfigPersonalPreferences {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    flex-direction: row;
    padding-left: 13px;
    padding-right: 13px;
    background-color: var(--dark-dark-three);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    height: 100%;
    color: var(--white-light);
    font-size: 18px;
    text-align: center;
    font-weight: 400;
    text-transform: uppercase;
}

.personalPreferences .bodyPersonalPreferences .lineFooterConfigPersonalPreferences {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
}

.personalPreferences .bodyPersonalPreferences .lineFooterConfigPersonalPreferences .columnFooterConfigPersonalPreferences {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    flex-direction: row;
    padding-left: 13px;
    padding-right: 13px;
    background-color: var(--dark-dark-three);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 100%;
}

.personalPreferences .bodyPersonalPreferences .lineFooterConfigPersonalPreferences .columnFooterConfigPersonalPreferences .buttonFooterConfigPersonalPreferences {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
    width: 100px!important;

}

.personalPreferences .bodyPersonalPreferences .lineFooterConfigPersonalPreferences .columnFooterConfigPersonalPreferences .buttonFooterConfigPersonalPreferences:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.personalPreferences .bodyPersonalPreferences .lineFooterConfigPersonalPreferences .columnFooterConfigPersonalPreferences .buttonFooterConfigPersonalPreferences.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.personalPreferences .bodyPersonalPreferences .lineFooterConfigPersonalPreferences .columnFooterConfigPersonalPreferences .buttonFooterConfigPersonalPreferences.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}
.companyPreferences {
    height: 100vh;
    position: relative;
    overflow: hidden;
}

.companyPreferences .scrollCompanyPreferences {
    overflow: scroll;
    height: 100%;
    margin-bottom: 300px;
}

.companyPreferences .headerCompanyPreferences {
    margin-top: 20px;
}

.companyPreferences .headerCompanyPreferences h4 {
    margin: 0;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.companyPreferences .headerCompanyPreferences h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-warning);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.companyPreferences .bodyCompanyPreferences {
    margin-bottom: 300px;
}

.companyPreferences .bodyCompanyPreferences .menusConfigCompanyPreferences .buttonMenus {
    background-color: transparent;
    border-bottom: 2px solid transparent;
    font-weight: 400;
}

.companyPreferences .bodyCompanyPreferences .menusConfigCompanyPreferences .buttonMenus.active {
    border-bottom: 2px solid var(--color-focus);
}

.companyPreferences .bodyCompanyPreferences .menusConfigCompanyPreferences .buttonMenus:hover {
    border-bottom: 2px solid var(--white-dark-two);
    transition: border 1.5s;
}

.companyPreferences .bodyCompanyPreferences .menusConfigCompanyPreferences .buttonMenus.active:hover {
    border-bottom: 2px solid var(--color-focus);
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences {
    width: 100%;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences {
    width: 100%;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .subtitleBodyConfigCompanyPreferences {
    color: #747678;
    font: 400 15px/20px 'Source Sans Pro', sans-serif;
    margin-bottom: 4px;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .buttonPlusFinancialServicesConfigCompanyPreferences,
.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .buttonPlusReasonConfigCompanyPreferences {
    background-color: var(--color-success);
    color: var(--white-light);
    border-radius: 50%;
    margin-bottom: 10px;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .editReasonConfigCompanyPreferences {   
    height: 150px;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 900;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences { 
    position: absolute;
    top: 0;
    right: 0;
    width: 340px;
    z-index: 900;
    padding: 0;
    margin: 0;
    height: 245px;
    background-color: var(--white-light);
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: var(--white-light);
    box-shadow: 0px 0px 7px 1px var(--white-dark-two);
    overflow: hidden;    
    margin-left: auto;
    margin-right: auto;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences .dropHeaderEditFinancialServicesConfigCompanyPreferences {
    padding: 15px;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences .dropHeaderEditFinancialServicesConfigCompanyPreferences .titleDropEditCompanyPreferences { 
    color: var(--dark-light);
    font-size: 16px;
    font-weight: 400;
    margin: 0;
    padding: 0;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences .dropBodyEditFinancialServicesConfigCompanyPreferences { 
    padding: 15px;
    padding-top: 0;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences .dropBodyEditFinancialServicesConfigCompanyPreferences .inputFinancialServicesConfigCompanPreferences { 
    margin-bottom: 10px;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences .dropFooterEditFinancialServicesConfigCompanyPreferences {
    border-top: 1px solid var(--white-dark-two);
    background-color: var( --white-dark);
    height: 100%;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    padding: 0 15px;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences .dropFooterEditFinancialServicesConfigCompanyPreferences .buttonDropEditCompanyPreferences {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 80px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
    /* width: 50px; */
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences .dropFooterEditFinancialServicesConfigCompanyPreferences .buttonDropEditCompanyPreferences:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences .dropFooterEditFinancialServicesConfigCompanyPreferences .buttonDropEditCompanyPreferences.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .dropEditFinancialServicesConfigCompanyPreferences .dropFooterEditFinancialServicesConfigCompanyPreferences .buttonDropEditCompanyPreferences.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .lineReasonBodyConfigCompanyPreferences {
    border-top: 1px solid #e5e5e5;
    padding: 5px 10px;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .lineReasonBodyConfigCompanyPreferences:hover {
    background-color: var(--white-dark);
    transition: background-color 0.8s;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .lineReasonBodyConfigCompanyPreferences:last-of-type {
    border-bottom: 1px solid #e5e5e5;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .nameReasonBodyConfigCompanyPreferences {
    color: var(--color-focus);
    cursor: pointer;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .lineReasonBodyConfigCompanyPreferences .buttonReasonBodyConfigCompanyPreferences {
    font-size: 10px;
    width: auto;
    width: initial;
    background-color: transparent;
    color: var(--color-focus);
    font-weight: 400;
    margin: 0;
    padding: 0;
    display: none;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .lineReasonBodyConfigCompanyPreferences:hover .buttonReasonBodyConfigCompanyPreferences {
    display: flex;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .lineReasonBodyConfigCompanyPreferences .buttonReasonBodyConfigCompanyPreferences.negative {
    color: var(--color-error);
    position: relative;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.companyPreferences .bodyCompanyPreferences .lineBodyConfigCompanyPreferences .columnBodyConfigCompanyPreferences .lineReasonBodyConfigCompanyPreferences .buttonReasonBodyConfigCompanyPreferences.negative::before {
    width: 2px;
    height: 2px;
    border-radius: 50%;
    content: " ";
    display: block;
    background-color: var(--primary-color);
    margin-left: 4px;
    margin-right: 4px;
}   

.companyPreferences .bodyCompanyPreferences .lineFooterConfigCompanyPreferences {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    width: 100%;
    height: 48px;
}

.companyPreferences .bodyCompanyPreferences .lineFooterConfigCompanyPreferences .columnFooterConfigCompanyPreferences {
    display: flex;
    justify-content: space-between;
    align-items: center;
    vertical-align: middle;
    flex-direction: row;
    padding-left: 13px;
    padding-right: 13px;
    background-color: var(--dark-dark-three);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 100%;
}

.companyPreferences .bodyCompanyPreferences .lineFooterConfigCompanyPreferences .columnFooterConfigCompanyPreferences .buttonFooterConfigCompanyPreferences {
    color: var(--primary-color);
    border-radius: 2px;
    margin: 0;
    height: 32px;
    padding: 4px;
    max-width: 120px;
    font-weight: 600;
    font-size: 15px;
    font-family: Source Sans Pro,sans-serif;
    background-color: var(--white-light);
    border-radius: 2px;
    border: 1px solid #26292c32;
    width: 100px!important;

}

.companyPreferences .bodyCompanyPreferences .lineFooterConfigCompanyPreferences .columnFooterConfigCompanyPreferences .buttonFooterConfigCompanyPreferences:hover {
    background-color: rgb(240, 240, 240);
    transition: background 1s;
}

.companyPreferences .bodyCompanyPreferences .lineFooterConfigCompanyPreferences .columnFooterConfigCompanyPreferences .buttonFooterConfigCompanyPreferences.positive {
    background-color: var(--color-success);
    color: var(--white-light);
    box-shadow: none;
    border: none;
}

.companyPreferences .bodyCompanyPreferences .lineFooterConfigCompanyPreferences .columnFooterConfigCompanyPreferences .buttonFooterConfigCompanyPreferences.positive:hover {
    opacity: 0.9;
    transition: opacity 0.6s;
}
.unitCompany {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.unitCompany .containerNavigationHeaderUnitCompany {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.unitCompany .containerNavigationHeaderUnitCompany .buttonNavigationHeaderUnitCompany {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.unitCompany .containerNavigationHeaderUnitCompany .buttonNavigationHeaderUnitCompany:hover {
    background-color: var(--white-dark);
}

.unitCompany .containerNavigationHeaderUnitCompany .buttonNavigationHeaderUnitCompany:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.unitCompany .containerNavigationHeaderUnitCompany .buttonNavigationHeaderUnitCompany:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.unitCompany .containerNavigationHeaderUnitCompany .buttonNavigationHeaderUnitCompany.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.unitCompany .containerNavigationHeaderUnitCompany .buttonPlusNavigationHeaderUnitCompany {
    width: 120px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.unitCompany .containerNavigationHeaderUnitCompany .buttonTrashNavigationHeaderUnitCompany {
    font-size: 10px;
    width: 32px;
    height: 32px;
    color: var(--primary-color);
    background-color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
    border: 1px solid var(--white-dark-two);
}
.tableEditUnitCompany {
    background-color: var(--white-light);
}

.tableEditUnitCompany .helpTableEditUnitCompany {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;

}

.tableEditUnitCompany table, tr, th, td {
    margin: 0;
}

.tableEditUnitCompany table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditUnitCompany table .buttonCheckTableEditUnitCompany {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.tableEditUnitCompany table .buttonCheckTableEditUnitCompany .squareCheck {
    border: 2px solid var(--white-dark-two);
    margin: 0;
    border-radius: 2px;
}

.tableEditUnitCompany table .buttonCheckTableEditUnitCompany.active .squareCheck {
    background-color: var(--color-focus);
    border: 2px solid var(--color-focus);
}

.tableEditUnitCompany table .buttonCheckTableEditUnitCompany.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.tableEditUnitCompany table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
  
}

.tableEditUnitCompany table tr {
    height: 32px;
}

.tableEditUnitCompany .theadTableEditUnitCompany td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
}

.tableEditUnitCompany .theadTableEditUnitCompany td:last-of-type {
    border-right: none;
}

.tableEditUnitCompany td.checkTableEditUnitCompany {
    text-align: center;
    vertical-align: middle;
    width: 35px!important;
    padding: 0;
}

.tableEditUnitCompany .tbodyTableEditUnitCompany:hover {
    background-color: var(--white-dark);
}

.tableEditUnitCompany .tbodyTableEditUnitCompany td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
}

.tableEditUnitCompany .table .tbodyTableEditUnitCompany td.checkTableEditUnitCompany {
    border: 1px solid #eee;
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
}

.tableEditUnitCompany .tbodyTableEditUnitCompany td:hover .buttonEditTableEditUnitCompany {
    opacity: 1;
}

.tableEditUnitCompany .tbodyTableEditUnitCompany td:hover .editTableEditUnitCompany {    
    border: 1px solid var(--color-focus)!important;
}

.tableEditUnitCompany .tbodyTableEditUnitCompany td .editTableEditUnitCompany.noEdit .buttonFieldEdit {
    display: none;
}

.tableEditUnitCompany .tbodyTableEditUnitCompany td .stampEditOwners {
    position: absolute;
    z-index: 900;
    height: 20px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    font-size: 11px;
    padding: 1px 4px;
    border-radius: 2px;
    color: var(--white-light);
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 8px;
    text-align: center;
    color: var(--white-light);

}

.tableEditUnitCompany .tbodyTableEditUnitCompany td .editTableEditUnitCompany .modalFieldEdit {
    z-index: 1000;
}

.tableEditUnitCompany .tbodyTableEditUnitCompany td .buttonShowConfigTableEditUnitCompany {
    background-color: transparent;
    width: 100%;
    font-size: 14px;
    color: var(--color-focus);
    margin-left: auto;
    margin-right: auto;
    position: relative;
    border: 1px solid #eee;
    width: 100%;
    height: 32px;
}
.dashboard {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.dashboard .headerDashboard {
}

.dashboard .containerBodyDashboard {
    background-color: var(--white-dark);
    position: relative;
    height: calc(100vh - 56px); /*56px by header component*/
    overflow: scroll;
}

.dashboard .containerBodyDashboard .lineBodyDashboard {
    width: 100%;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard {
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaLegendChartSimpleBarDashboard {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    vertical-align: middle;
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaLegendChartSimpleBarDashboard span::before {
    background-color: var(--color-success);
    width: 10px;
    height: 10px;
    border-radius: 2px;
    content: " ";
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    margin-right: 5px;
    font-size: 14px;
    color: var(--dark-dark);
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaLegendChartSimpleBarDashboard span.success::before {
    background-color: var(--color-success);
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaLegendChartSimpleBarDashboard span.error::before {
    background-color: var(--color-error);
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaLegendChartSimpleBarDashboard span.warning::before {
    background-color: var(--color-warning);
}

.dashboard .containerBodyDashboard .lineBodyDashboard .columnBodyDashboard .areaLegendChartSimpleBarDashboard span.information::before {
    background-color: var(--color-information);
}
.cardChartPie {
    position: relative;
    width: 200px;
}

.cardChartPie .labelOverCardChartPie {
    /* start alignment */
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    width: 130px;
    height: 130px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    /* ends alignment */   
    

    /* start general style */
    border: 2px solid var(--color-success);
    border-style: dotted;
    border-radius: 50%;
    font-weight: 600;
    font-size: 42px;
    color: var(--color-success);
    /* ends general style */
}
.cardInfoLeftChart {
    background-color: #fff;
    padding: 30px;
    margin: 20px;
    border-radius: 20px;
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 16%), 0 2px 2px 0 rgb(0 0 0 / 5%), 0 3px 1px -2px rgb(0 0 0 / 6%), 0 1px 5px 0 rgb(0 0 0 / 10%);
    background-color: var(--white-light);
    box-sizing: border-box;
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart {
    width: 100%;
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft {
    width: 100%;
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft h4 {
    margin: 0;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-success);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft h4.focus::before {
    background-color: var(--color-focus);
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft h4.error::before {
    background-color: var(--color-error);
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft h4.warning::before {
    background-color: var(--color-warning);
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft h4.information::before {
    background-color: var(--color-information);
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft span {
    font-weight: 300;
    color: var(--dark-dark);
    display: inline-block;
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnChartCardInfoLeftChart {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}
.cardInfoRightChart {
    background-color: #fff;
    padding: 30px;
    margin: 20px;
    border-radius: 20px;
    box-shadow: 0 0 1px 0 rgb(0 0 0 / 16%), 0 2px 2px 0 rgb(0 0 0 / 5%), 0 3px 1px -2px rgb(0 0 0 / 6%), 0 1px 5px 0 rgb(0 0 0 / 10%);
    background-color: var(--white-light);
    box-sizing: border-box;
}

.cardInfoRightChart .lineInfoCardInfoRightChart {
    width: 100%;
}

.cardInfoRightChart .lineInfoCardInfoRightChart .columnInfoCardInfoLeft {
    width: 100%;
}

.cardInfoRightChart .lineInfoCardInfoRightChart .columnInfoCardInfoLeft h4 {
    margin: 0;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    padding-left: 30px;
}

.cardInfoRightChart .lineInfoCardInfoRightChart .columnInfoCardInfoLeft h4::before {
    position: absolute;
    left: -10px;
    display: block;
    content: " ";
    width: 25px;
    height: 25px;
    background-color: var(--color-success);
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft h4.focus::before {
    background-color: var(--color-focus);
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft h4.error::before {
    background-color: var(--color-error);
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft h4.warning::before {
    background-color: var(--color-warning);
}

.cardInfoLeftChart .lineInfoCardInfoLeftChart .columnInfoCardInfoLeft h4.information::before {
    background-color: var(--color-information);
}

.cardInfoRightChart .lineInfoCardInfoRightChart .columnInfoCardInfoLeft span {
    font-weight: 300;
    color: var(--dark-dark);
    display: inline-block;
}

.cardInfoRightChart .lineInfoCardInfoRightChart .columnChartCardInfoRightChart {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}
.commissionRules {
    height: 100%;
    position: relative;
    overflow: hidden;
}

.commissionRules .containerNavigationHeaderCommissionRules {
    height: 65px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
}

.commissionRules .containerNavigationHeaderCommissionRules .buttonNavigationHeaderCommissionRules {
    background: var(--white-light);
    color: var(--primary-color);
    font-weight: 600;
    font-size: 15px;
    border-radius: 0;
    height: 32px;    
    min-width: 47px;
    padding: 4px;
    vertical-align: top;    
    border-radius: 2px;
    border: 1px solid #cbcccd;
}

.commissionRules .containerNavigationHeaderCommissionRules .buttonNavigationHeaderCommissionRules:hover {
    background-color: var(--white-dark);
}

.commissionRules .containerNavigationHeaderCommissionRules .buttonNavigationHeaderCommissionRules:first-of-type{
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
}

.commissionRules .containerNavigationHeaderCommissionRules .buttonNavigationHeaderCommissionRules:nth-child(2) {
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}

.commissionRules .containerNavigationHeaderCommissionRules .buttonNavigationHeaderCommissionRules.active {
    border: 1px solid var(--color-focus);
    color: var(--color-focus);
    background-color: #e8f0fb;
}

.commissionRules .containerNavigationHeaderCommissionRules .buttonPlusNavigationHeaderCommissionRules {
    width: 120px;
    height: 32px;
    color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
}

.commissionRules .containerNavigationHeaderCommissionRules .buttonTrashNavigationHeaderCommissionRules {
    font-size: 10px;
    width: 32px;
    height: 32px;
    color: var(--primary-color);
    background-color: var(--white-light);
    font-weight: 600;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
    padding: 0;
    box-shadow: 0 1px 2px rgb(38 41 44 / 8%);
    border: 0;
    border-radius: 2px;
    margin-left: 15px;
    border: 1px solid var(--white-dark-two);
}

.commissionRules .containerNavigationHeaderCommissionRules .filterFontsHeaderCommissionRules {
    min-width: 220px;
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
    background-color: var(--white-light);
    border: 1px solid var(--white-dark-two);
}

.commissionRules .containerNavigationHeaderCommissionRules .filterFontsHeaderCommissionRules .iconFilterFontsHeaderCommissionRules {
    color: red;
    font-size: 14px;
    color: var(--dark-dark);
}

.commissionRules .containerNavigationHeaderCommissionRules .filterFontsHeaderCommissionRules .selectFontHeaderCommissionRules {
    cursor: pointer;
    width: 100%;
    border: none;
}

.commissionRules .containerNavigationHeaderCommissionRules .filterFontsHeaderCommissionRules .selectFontHeaderCommissionRules .textShowSelectMulti {
    text-align: center;
    width: 90%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 600;
}

.commissionRules .containerNavigationHeaderCommissionRules .filterFontsHeaderCommissionRules .selectFontHeaderCommissionRules .fieldSelectMulti {
    border: none;
}
.tableEditCommissionRules {
    background-color: var(--white-light);
}

.tableEditCommissionRules .helpTableEditCommissionRules {
    position: fixed;
    top: -200px; /*position default*/
    left: -200px; /*position default*/
    z-index: 900;
    border-radius: 5px;
    padding: 4px 12px;
    background-color: var(--primary-color);
    color: var(--white-light);
    font-size: 12px;

}

.tableEditCommissionRules table, tr, th, td {
    margin: 0;
}

.tableEditCommissionRules table {
    table-layout: fixed; 
    width: 100%;
}

.tableEditCommissionRules table .buttonCheckTableEditCommissionRules {
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.tableEditCommissionRules table .buttonCheckTableEditCommissionRules .squareCheck {
    border: 2px solid var(--white-dark-two);
    margin: 0;
    border-radius: 2px;
}

.tableEditCommissionRules table .buttonCheckTableEditCommissionRules.active .squareCheck {
    background-color: var(--color-focus);
    border: 2px solid var(--color-focus);
}

.tableEditCommissionRules table .buttonCheckTableEditCommissionRules.active .squareCheck .iconCheck {
    color: var(--white-light);
}

.tableEditCommissionRules table td {
    white-space: nowrap;     
    overflow: hidden;
    text-overflow: ellipsis; 
    padding: 0;
    padding-left: 10px;
    vertical-align: middle;
  
}

.tableEditCommissionRules table tr {
    height: 32px;
}

.tableEditCommissionRules .theadTableEditCommissionRules td {
    border: 1px solid #b9babb;
    border-left: 1px solid transparent;
    font-weight: 600;
    color: var(--primary-color);
    font-size: 15px;
    position: relative;
}

.tableEditCommissionRules .theadTableEditCommissionRules td:last-of-type {
    border-right: none;
}

.tableEditCommissionRules td.checkTableEditCommissionRules {
    text-align: center;
    vertical-align: middle;
    width: 35px!important;
    padding: 0;
}

.tableEditCommissionRules .tbodyTableEditCommissionRules:hover {
    background-color: var(--white-dark);
}

.tableEditCommissionRules .tbodyTableEditCommissionRules td {
    font-size: 14px;
    border: none;
    padding: 0;
    margin: 0;
    position: relative;
}

.tableEditCommissionRules .table .tbodyTableEditCommissionRules td.checkTableEditCommissionRules {
    border: 1px solid #eee;
    width: 100%;
    height: 32px;
    position: relative;
    display: flex;
    align-items: center;
}

.tableEditCommissionRules .tbodyTableEditCommissionRules td:hover .buttonEditTableEditCommissionRules {
    opacity: 1;
}

.tableEditCommissionRules .tbodyTableEditCommissionRules td:hover .editTableEditCommissionRules {    
    border: 1px solid var(--color-focus)!important;
}

.tableEditCommissionRules .tbodyTableEditCommissionRules td .editTableEditCommissionRules.noEdit .buttonFieldEdit {
    display: none;
}

.tableEditCommissionRules .tbodyTableEditCommissionRules td .stampEditCommissionRules {
    position: absolute;
    z-index: 900;
    height: 20px;
    margin-top: 6px;
    margin-bottom: 6px;
    margin-left: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    font-size: 11px;
    padding: 1px 4px;
    border-radius: 2px;
    color: var(--white-light);
    font-weight: 600;
    text-transform: uppercase;
    margin-right: 8px;
    text-align: center;
    color: var(--white-light);

}

.tableEditCommissionRules .tbodyTableEditCommissionRules td .editTableEditCommissionRules .modalFieldEdit {
    z-index: 1000;
}
.login {
    max-width: 1024px;
    padding: 0;
}

.login .navigation {
    height: 90px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
}

.login .navigation .content {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
}

.login .navigation .brand {
    font-size: 42px;
    text-decoration: none;
    color: #26292c;
    font-weight: 700;
}

.login .navigation .content .information {
    font-size: 15px;
    margin-right: 20px;
    color: rgba(38,41,44,0.88);
    font-weight: 300;
    font-family: "SourceSansPro", sans-serif;
}

.login .navigation .content .anchor {
    color: var(--primary-color);
    box-shadow: inset 0px 0px 0px 3px var(--primary-color);
    box-shadow: inset 0px 0px 0px 3px var(--primary-color);
    background: transparent;
    padding: 6px 15px;
    font-size: 20px;
    line-height: 1.5em;
    font-weight: 700;
    font-family: 'Source Sans Pro', sans-serif;
}

.login .form {
    background-color: var(--white-light);
    background-color: #fff;
    max-width: 500px;
    margin-top: 40px;
    padding: 40px 60px 52px;
}

.login .form .title {
    font-weight: 100;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #26292c;
    font-size: 34px;
    text-align: center;
    line-height: 1.2em;
    margin: 0 0 40px 0;
    font-weight: 900;
}

.login .form .group { 
    min-height: 83px;
    margin-bottom: 25px;
}

.login .form .group .anchorForgotPassword{ 
    text-decoration: none;
    position: absolute;
    right: 0;
    color: #888e94;
    text-decoration: none;
    font-size: 12px;
}

.login .form .group .buttonLogin{ 
    margin-bottom: 25px;
}

.login .form .spanError {
    visibility: hidden;
    margin-top: 5px;
    font-size: 14px;
    line-height: 1.5em;
    text-align: left;
    color: var(--color-error);
    min-height: 1.5em;
}

.login .footer {
    padding: 40px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    text-align: center;
}

.login .footer .linkSupport{
    color: #747678;
    text-decoration: none;
}

.login .footer .linkCRM{
    color: #26292c;
    text-decoration: none;
}

@media (max-width: 767px)
{
    .login .navigation {
        padding-left: 6px;        
    }

    .login .form {
        margin-top: 0px;
        padding: 40px 20px 20px 20px;
    }

    .login .form .title {
        font-size: 24px;
    }

    .login .navigation .content .anchor{
        padding: 5px 10px;
        font-size: 14px;
        line-height: 1.5em;
    }

}
.groupInput {
    position: relative;
}

.groupInput .input { 
    position: relative;
    flex-direction: row;
    display: flex;
    align-items: center;
    vertical-align: middle;
    overflow: hidden;
}

.groupInput .label {
    position: absolute;
    z-index: 400;
    color: #b9babb;
    font-size: 20px;
    -webkit-transform: scale(1) translate(30px, 10px);
            transform: scale(1) translate(30px, 10px);
}

.groupInput .label.yesFocus {
    /* color: green; */
    transition: -webkit-transform 0.4s;
    transition: transform 0.4s;
    transition: transform 0.4s, -webkit-transform 0.4s;
    -webkit-transform: scale(0.7) translate(-8px, -25px);
            transform: scale(0.7) translate(-8px, -25px);
}

.groupInput .label.noFocus {
    /* color: red; */
    transition: -webkit-transform 0.4s;
    transition: transform 0.4s;
    transition: transform 0.4s, -webkit-transform 0.4s;
    -webkit-transform: scale(1) translate(30px, 10px);
            transform: scale(1) translate(30px, 10px);
}

.groupInput .input i.icon {
    left: 0;
    position: absolute;
    color: #b9babb;
    font-size: 20px;
}

.groupInput .input i.icon.yesFocus {
    /* color: green; */
    margin-left: -30px;
    transition: margin-left 0.3s;
}

.groupInput .input i.icon.noFocus {
    margin-left: 0px;
    transition: margin-left 0.3s;
    /* color: red; */
}

.groupInput .input .field{ 
    margin: 0;
    height: 51px;
    border: none;
    font-size: 20px;
    border: 0;
    color: var(--primary-color);
    outline: none;
    border-radius: 0;
    flex: 1 1;
    transition: padding 0.2s, background-position 0.2s ease;
    border-bottom: 2px solid #cccbdd;
    font-family: 'Source Sans Pro', sans-serif;
    padding-left: 10px;
    z-index: 500;
    background-color: transparent;
}

.groupInput .input .field.noFocus {
    /* border: 2px solid red; */
}

.groupInput .input .field.yesFocus {
    /* border: 2px solid green; */
    border-bottom: 2px solid #08a742;
}
